<template>
  <div class="container clearfix">
    <router-view class="router-view" v-slot="{ Component }">
      <transition class="transition" :name="transitionName">
          <component :is="Component" />
      </transition>
    </router-view>
	<Loginstatus />
    <TabBar />
  </div>
</template>

<script setup>
import TabBar from '@/components/TabBar'
import Loginstatus from '@/components/loginstatus'
import {ref, watch} from "vue";
import {useRouter} from "vue-router";

const transitionName = ref('left')//默认动画
const router = useRouter();
watch(
    ()=> router.currentRoute.value,
    (newValue, oldValue)=>{
      const toIndex = newValue.meta.index
      const fromIndex = oldValue.meta.index
      transitionName.value = toIndex < fromIndex ? 'right' : 'left'
      console.log(transitionName.value)
    }
)
</script>

<style lang="scss" scoped>
.container{
  width: 100%;
  overflow-x: hidden;
  position: relative;

  .router-view{
    height: calc(100vh - 50px);
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}
.left-enter-from {
  opacity: 0;
  transform: translateX(100%);
}
.left-enter-to {
  opacity: 1;
  transform: translateX(0);
}
.left-leave-from {
  opacity: 1;
  transform: translateX(0);
}
.left-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.right-enter-from {
  opacity: 0;
  transform: translateX(-100%);
}
.right-enter-to {
  opacity: 1;
  transform: translateX(0);
}
.right-leave-from {
  opacity: 1;
  transform: translateX(0);
}
.right-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.right-enter-active,
.right-leave-active,
.left-enter-active,
.left-leave-active {
  transition: all ease-in-out .5s;
}

</style>
