<template>
	<div class="daikuans" v-if="dkstatus" @click="gotodaikuan">
		<img src="../../static/nft/home/daikuan/icon-wallet2.png" style="width: 54px;height: 54px;" />
	</div>
	
	<van-dialog style="background: transparent" v-model:show="dkkstatus" show-cancel-button :closeOnClickOverlay="true" :showConfirmButton="false" :showCancelButton="false">
		
		<div  class="dialog-wrap">
			<!---->
			<div  class="dialog-content">
				<div  class="dialog-content">
					<img  src="../../static/nft/home/daikuan/borrow-window-bg.6bea1b9a.png" alt="" class="bg">
					<div  class="dialog-main">
						<div  class="tag">
							<img  src="../../static/nft/home/daikuan/icon-1.png" alt="" class="tag-bg">
							<div  class="tag-box"> Loan </div>
						</div>
						<div  class="text-content">
							<div  class="title golden">Congratulations</div>
							<div  class="text golden"> Your credit score is excellent, which qualifies you for a loan application.  You need only to submit an application. The customer service department will process a loan amount corresponding to your credit score </div>
						</div>
						<div  class="button-view">
							<div  class="button-item">
								<div  class="button-box">Submit application form</div>
							</div>
							<div  class="button-item">
								<div  class="button-box">Obtain a loan</div>
								<div  class="tip golden">Complete the previous task</div>
							</div>
						</div>
						<div  class="info-view">
							<div  class="tip">Continuously complete tasks to accumulate credit points, allowing you to qualify for the highest loan amount available</div>
							<div  class="line"></div>
							<div  class="tr">
								<div  class="th">Quantity</div>
								<div  class="th">Days</div>
								<div  class="th">Interest</div>
							</div>
							<div  class="line"></div>
							<div  class="tr">
								<div  class="td">{{dkinfo.num}} USDT</div>
								<div  class="td">{{dkinfo.tian}} Days</div>
								<div  class="td">{{dkinfo.interest}} USDT</div>
							</div>
						</div>
					</div>
				</div>
				<div  class="button-yes" @click="gotodaikuantiaokuang">
					<img  src="../../static/nft/home/daikuan/icon-2.png" alt="" class="link-btn">
					<div  class="button-box"> Accept
						<img  src="../../static/nft/home/daikuan/icon-3.png" alt="" class="link-icon">
					</div>
				</div>
			</div>
		</div>
		
	</van-dialog>
	
	<van-dialog v-model:show="dktkstatus" show-cancel-button :showConfirmButton="false" :showCancelButton="false">
		
		<div  class="dialog-wrap bgColor">
			<div  class="dialog-head">
				<div  class="title">Debit and credit rules</div>
				<i @click="guanbidkgz"  class="close-icon van-icon van-icon-cross">
				</i>
			</div>
			<div  class="dialog-content">
				<div  class="rule-content">
					<div  class="rule-main">
						<p>The User Service Agreement for Pledge Loan (hereinafter referred to as "Agreement") is an agreement reached between the platform (hereinafter referred to as "the platform") and the user (“You”) regarding your use of the pledge loan service provided by the platform (“Service”). (“Service”). By accessing, logging in, and utilizing the platform and its provided services, you acknowledge that you have read, comprehended, and agreed to all the terms and conditions stipulated in this Service Agreement. If you do not agree with any terms or conditions of this Agreement, please stop accessing the platform and discontinue using the services. Should you continue to access the platform or use the services, it will be considered that you have unconditionally agreed to the entire content of this agreement.</p>
						<p>I. Scope and application of this Agreement. This Agreement applies to your pledge loan activities on the platform.</p>
						<p>2.Risk Warning: Engaging in pledge loans on this platform may offer you higher investment returns. In accordance with relevant laws, regulations, administrative rules, and national policies, we hereby provide you with risk warnings related to pledging and lending to enhance your understanding of the associated risks. Please read carefully and in detail.  The risks involved in pledging and lending on the platform include, but are not limited to:</p>
						<p>(1)Risk Warning: The platform guarantees that all information, programs, texts, etc., within the platform are completely secure and free from interference and destruction by viruses, Trojans, and other malicious programs. Should you log in or browse any other links, information, data, etc., it is your personal decision, and you shall bear the risk and any potential losses.</p>
						<p>(2)Force Majeure Risk: The platform assumes no responsibility for any loss caused by maintenance of information network equipment, failure of information network connections, failures of computers, communication or other systems, power failures, hacker attacks, weather, accidents, etc. Strikes, labor disputes, riots, insurrections, disturbances, shortages of productivity or production resources, fires, floods, storms, explosions, wars, issues arising from banks or other partners, collapses in the digital asset market, governmental actions, orders from judicial or administrative authorities, or any acts beyond the platform\'s control, beyond its capacity to control, or actions by third parties, which result in the inability to provide services or delays in providing services, leading to losses for you, shall not be the responsibility of the platform. </p>
						<p>(3) Risk of Seizure or Freezing: When relevant authorities present investigation documents requiring the platform to cooperate in an investigation of your account, assets, or transaction data on the platform, or to take measures to seize, freeze, or transfer your account, the platform will assist in providing your relevant data or carry out operations in accordance with the requirements of the supervisory authorities. Any resulting privacy leaks, inability to operate the account, and losses will be the user’s sole responsibility.</p>
						<p>(4)You shall bear the losses caused by the following situations: (a) Losses caused by losing your account, forgetting your password, improper operation, or investment decision mistakes; (b) Losses caused by malicious operations by others after online commissioning or hotkey operations without timely logging out; (c) Losses caused by entrusting others to pledge or lend on the platform on your behalf; (d) Other accidental events and losses not caused by the platform.</p>
						<p>Special Warning: You should control your own risks by participating in pledge loans on this platform to evaluate your asset investment value and investment risks, and bear the economic risks of losing the entire investment; You are advised to make transactions in accordance with your financial condition and risk tolerance</p>
						<p>3.Definitions. Under this Agreement, the terms listed below are defined as follows.</p>
						<p>(1) Pledge Loan: Refers to the act of pledging a certain amount of USDT assets on the platform to borrow a certain amount of USDT assets from the platform.</p>
						<p>（2）Pledged Funds: Refers to the USDT you voluntarily pledge to the platform to apply for a loan, which is deposited into the platform’s Antique account, allowing the platform to perform restricted transactions, transfers, and withdrawals</p>
						<p>4. Applying for a Loan: After logging into the platform, you can click "Receive" on the pledge loan feature page. Upon clicking “Receive,”the system will automatically review your loan application. Once the pledge loan application is approved, the system will automatically disburse the loan funds to your account and temporarily transfer funds from your wallet to your account as pledge loans. These funds, including account pledge funds and loan funds, can be used for speculative bidding and trading. You can withdraw all the funds in your account once you return the borrowed funds and interest within the specified time.</p>
						<p>5.Loan Interest: Refers to the interest payable to the platform when utilizing the pledge coin loan services provided by the platform. The interest is calculated daily, with a daily interest rate ranging from 0.1% to 0.3%. For example, if you engage in a 10-day loan of 50,000 USDT with a daily interest rate of 0.1%, then your interest for 10 days would amount to 500 USDT.</p>
						<p>6. Loan Days: 7 to 30 days</p>
						<p>7. Utilization of Borrowed Assets: Borrowed assets will be credited to your account, allowing you to use these assets for bidding on and trading works on the platform, thereby generating income.</p>
						<p>VIII.Repayment Regulations: Funds pledged in the account cannot be used for repayment, nor can profits earned on the platform during the loan period. Platform users must deposit the necessary repayment funds and interest into their wallet for repayment. After the deposit is made, you can request the customer service department to return the borrowed funds. Once the repayment is approved, you are free to withdraw any funds from your account.</p>
						<p>9. Voluntary Action: Your engagement in pledge loans on the platform is a voluntary transaction based on your financial situation and understanding of the associated risks. Once a commitment is made, it cannot be revoked.</p>
						<p>10.Extension Request: You are required to provide your identification documents and the reason for the extension. The specific extension days will be determined based on the audit department\'s review of the extension request.</p>
						<p>11 Users must comply with relevant laws and regulations as well as the provisions of this agreement (including versions updated by the platform from time to time) when utilizing this service.</p>
						<p>12、Any agreements and terms signed between the user and the platform regarding the use of platform services, and any disputes arising therefrom, shall be resolved by the platform itself.</p>
					</div>
					<div @click="daikuansub"   class="button btn-view sincere" style="width: 100%; border-radius: 44px;"> Confirm Application </div>
				</div>
			</div>
		</div>
		
	</van-dialog>
	
	<van-dialog v-model:show="successDia" :style="{ background: 'rgba(0,0,0,0)' }" show-cancel-button :closeOnClickOverlay="true" :showConfirmButton="false" :showCancelButton="false">
		<img class="successImg" src="../../static/nft/home/success.png">
	</van-dialog>	
</template>

<script>
	import { getloanstatus,togetloansub } from '@/api/api'
	export default {
		data() {
		    return {
				dkstatus:false,
				dkkstatus:false,
				dktkstatus:false,
				successDia:false,
				dkinfo:[]
		    }
		},
		mounted() {
			this.dkonload()
		},
		methods: {
			dkonload(){
				getloanstatus().then(res => {
					console.log('贷款信息',res.data)
					if(res.data.status !==0 && res.data.info != null && res.data.info != ''){
						this.dkstatus = true
						this.dkinfo = res.data.info
					}
				}).catch((error) => {
					console.log(error.message)
				})
			},
			gotodaikuan(){
				this.dkkstatus = true
			},
			gotodaikuantiaokuang(){
				this.dktkstatus = true
				this.dkkstatus = false
			},
			guanbidkgz(){
				this.dktkstatus = false
			},
			daikuansub(){
				 togetloansub().then(res => {
					this.dktkstatus = false
					this.successDia = true
					const that = this
					setTimeout(()=>{
						/*
						that.$router.push({
						    path: "/capital",
							query: {
							  active: 1
							}
						});
						*/
					   window.location.reload();
					},1000);
				 }).catch((error) => {
				 	console.log(error.message)
					showToast(error.message)
				 })
			}
		}
	}
</script>

<style scoped>
	.daikuans{
		position: fixed;
		/* bottom: 140px; */
		top:275px;
		right: 15px;
		z-index: 50;
		/* width: 60px; */
	}
	.van-dialog{
		background: transparent !important;
	}
	
	.dialog-wrap.bgColor{
		background-color: #fff
	}
	
	.dialog-wrap .dialog-head{
		height: 16.267vw;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		border: .267vw solid #f4f5f7;
		box-sizing: border-box
	}
	
	.dialog-wrap .dialog-head .title{
		font-size: 4vw;
		font-weight: 500;
		color: #27282c
	}
	
	.dialog-wrap .dialog-head .close-icon{
		font-size: 5.333vw;
		color: #a7aaae;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 4.8vw
	}
	
	.golden {
		background: linear-gradient(105deg, #facd6f, #ffe8ba 41%, #fcd27e);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent
	}
	
	.dialog-content {
		position: relative;
		height: calc(100% + 10.667vw)
	}
	
	.dialog-content .bg {
		width: 100%;
		display: block
	}
	
	.dialog-content .dialog-main {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0
	}
	
	.dialog-content .dialog-main .tag {
		width: 21.333vw;
		position: relative;
		top: 2%
	}
	
	.dialog-content .dialog-main .tag .tag-bg {
		width: 100%;
		display: block
	}
	
	.dialog-content .dialog-main .tag .tag-box {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 3.0vw;
		font-weight: 500;
		color: #362977;
		display: flex;
		align-items: center;
		padding-left: 3.733vw;
		box-sizing: border-box
	}
	
	.dialog-content .dialog-main .text-content {
		padding: 7.733vw 8.533vw 0;
		box-sizing: border-box;
		color: #2b1773;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center
	}
	
	.dialog-content .dialog-main .text-content .title {
		font-size: 7.267vw;
		font-weight: 600;
		line-height: 10.667vw
	}
	
	.dialog-content .dialog-main .text-content .text {
		font-size: 2.733vw;
		font-weight: 400;
		line-height: 4.267vw
	}
	
	.dialog-content .dialog-main .button-view {
		width: calc(100% - 13.067vw);
		height: 21.333vw;
		position: absolute;
		left: 6.533vw;
		top: 57.467vw;
		display: flex;
		flex-direction: column;
		justify-content: space-between
	}
	
	.dialog-content .dialog-main .button-view .button-item {
		display: flex;
		justify-content: space-between;
		background: #261e18;
		border-radius: 2.133vw
	}
	
	.dialog-content .dialog-main .button-view .button-item .button-box {
		height: 9.333vw;
		background: linear-gradient(90deg, #62ecb7, #a3f4d5 22%, #68edb9 47%, #a4f4d6 69%, #62ecb7);
		border-radius: 2.133vw;
		box-sizing: border-box;
		padding: 0 5.067vw;
		font-size: 3.533vw;
		font-weight: 500;
		color: #362977;
		display: flex;
		align-items: center
	}
	
	.dialog-content .dialog-main .button-view .button-item .tip {
		flex: 1;
		overflow: hidden;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding-left: 2.667vw
	}
	
	.dialog-content .dialog-main .info-view {
		width: calc(100% - 13.067vw);
		height: 33.333vw;
		position: absolute;
		top: 78.8vw;
		left: 6.533vw;
		background-color: rgba(0, 0, 0, .12);
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;
		padding: 0 7.467vw;
		background-color: #9ea8c0
	}
	
	.dialog-content .dialog-main .info-view .tip {
		font-size: 3.267vw;
		font-weight: 500;
		color: #4d11b6;
		text-align: center;
		margin-bottom: 1.6vw
	}
	
	.dialog-content .dialog-main .info-view .line {
		width: calc(100% - 8vw);
		margin: 1.6vw auto;
		border: .267vw solid #342174;
		height: 0
	}
	
	.dialog-content .dialog-main .info-view .tr {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: calc(100% - 8vw);
		margin: 0 auto;
		font-size: 2.733vw;
		color: #4d11b6
	}
	
	.dialog-content .dialog-main .info-view .tr .th {
		font-weight: 500
	}
	
	.dialog-content .dialog-main .info-view .tr .td {
		font-weight: 700
	}
	
	.dialog-content .button-yes {
		position: relative
	}
	
	.dialog-content .button-yes .link-btn {
		width: 100%;
		display: block
	}
	
	.dialog-content .button-yes .button-box {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 4.067vw;
		font-weight: 600;
		color: #fff
	}
	
	.dialog-content .button-yes .button-box .link-icon {
		width: 4.267vw;
		margin-left: 2.133vw;
		display: block
	}
	
	
	.button {
		width: 100%;
		height: 11.733vw;
		border-radius: 2.133vw;
		opacity: .98;
		border: .267vw solid #adb0bb;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 4.067vw;
		font-weight: 500;
		color: #adb0bb
	}
	
	.button.is-disabled {
		pointer-events: none
	}
	
	.button.sincere {
		background-color: #253340;
		border-color: #253340;
		color: #fff
	}
	
	.button.white {
		background-color: #fff;
		border-color: #fff;
		color: #1a1b1c
	}
	
	.button.disabled .button.info {
		background-color: #cbcbcb;
		border-color: #cbcbcb;
		color: #fff
	}
	
	.button.disabled {
		pointer-events: none
	}
	
	.rule-content {
	    padding: 4vw;
	    box-sizing: border-box;
	}
	.rule-content .rule-main{
	    height: 40vh;
	    overflow-y: auto;
	    margin-bottom: 4vw;
		color:#000000;
	}
	.button.sincere {
	    background-color: #253340;
	    border-color: #253340;
	    color: #fff;
	}
	.button {
	    width: 100%;
	    height: 11.733vw;
	    border-radius: 2.133vw;
	    opacity: .98;
	    border: 0.267vw solid #adb0bb;
	    box-sizing: border-box;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    font-size: 4.267vw;
	    font-weight: 500;
	    color: #adb0bb;
	}
	.successImg{
		width: 40%;
		margin: 0 auto;
		display: block;
	}
</style>