<template>
  <div class="sign_pop">
    <div class="con">
        <div class="bar">
            {{$t('newtext1')}}           <img class="close" src="./static/image/icon_close.png" />
        </div>
            <div class="ele">
                <img class="money" src="./static/image/boosting/icon_money.png" />
                <img class="sign" src="./static/image/boosting/icon_sign.png" />
                <b>1</b>
                <div class="bottom">{{$t('newtext2')}}</div>
            </div>
            <div class="ele">
                <img class="money" src="./static/image/boosting/icon_money.png" />
                <img class="sign" src="./static/image/boosting/icon_sign.png" />
                <b>5</b>
                <div class="bottom">{{$t('newtext3')}}</div>
            </div>
            <div class="ele">
                <img class="money" src="./static/image/boosting/icon_money.png" />
                <img class="sign" src="./static/image/boosting/icon_sign.png" />
                <b>10</b>
                <div class="bottom">{{$t('newtext4')}}</div>
            </div>
            <div class="ele">
                <img class="money" src="./static/image/boosting/icon_money.png" />
                <img class="sign" src="./static/image/boosting/icon_sign.png" />
                <b>20</b>
                <div class="bottom">{{$t('newtext5')}}</div>
            </div>
            <div class="ele">
                <img class="money" src="./static/image/boosting/icon_money.png" />
                <img class="sign" src="./static/image/boosting/icon_sign.png" />
                <b>30</b>
                <div class="bottom">{{$t('newtext6')}}</div>
            </div>
            <div class="ele">
                <img class="money" src="./static/image/boosting/icon_money.png" />
                <img class="sign" src="./static/image/boosting/icon_sign.png" />
                <b>40</b>
                <div class="bottom">{{$t('newtext7')}}</div>
            </div>
            <div class="ele2">
                <img class="money" src="./static/image/boosting/icon_money.png" />
                <img class="sign" src="./static/image/boosting/icon_sign.png" />
                <b>50</b>
                <div class="bottom">{{$t('newtext8')}}</div>
            </div>
                <div class="sign_btn">
                        {{$t('newtext9')}}                </div>
    </div>
</div>

 <div id="signSuc" v-if="false" style="
                        position: fixed;
                        color:white;
                        font-size: 12px;
                        text-align: center;
                        padding:3px;
                        top: 0;
                        right:0;
                        bottom:0;
                        left: 0;
                        margin: auto;
                        background: rgba(0,0,0,.7);
                        width: 100px;
                        height: 30px;
                        line-height: 26px;
                        border-radius: 6px;
                        z-index: 999;
                        display: none;
                    ">签到成功</div>

 <div id="signed" v-if="false" style="
                        position: fixed;
                        color:white;
                        font-size: 12px;
                        text-align: center;
                        padding:3px;
                        top: 0;
                        right:0;
                        bottom:0;
                        left: 0;
                        margin: auto;
                        background: rgba(0,0,0,.7);
                        width: 100px;
                        height: 30px;
                        line-height: 26px;
                        border-radius: 6px;
                        z-index: 999;
                        display: none;
                    ">今日已经签到</div>
  <div class="singing-main" style="position:sticky;height: auto;top: auto">
	  
    <div class="header" style="position: absolute;
    top: 10px;
    left: 0;
    z-index: 99999;width: 100%;">
      <div class="header-left">
        <img src="../../static/img/singing/singing-logo.png">
        <span>{{ $t('singing.logo_title') }}</span>
      </div>
      <div class="header-right" v-if="false">
        <div class="dream-header" style="display: block;position: static;background: #1C377C;width: 70%;">
      <!--语言选择-->
      <div
          class="lang_cur_s"
          style="display: block"
          v-if="recordTheCurrent == 'zh'"
          @click="openLanguage()"
        >
          <img id="vector" src="../../static/img/singing/Vector.png" />
          简体

          <img class="login-logo" id="login-logo" src="./static/image/arrow_bottom.png" />
        </div>
        <div
          class="lang_cur_t"
          style="display: block"
          v-if="recordTheCurrent == 'tc'"
          @click="openLanguage()"
        >
          <img id="vector" src="../../static/img/singing/Vector.png"/>
          繁体
          <img class="login-logo" id="login-logo" src="./static/image/arrow_bottom.png" />
        </div>
        <div
          class="lang_cur_e"
          style="display: block"
          v-if="recordTheCurrent == 'en'"
          @click="openLanguage()"
        >
          <img id="vector" src="../../static/img/singing/Vector.png"/>
          English
          <img class="login-logo" id="login-logo" src="./static/image/arrow_bottom.png" />
        </div>
        <div
          class="lang_cur_j"
          style="display: block"
          v-if="recordTheCurrent == 'jp'"
          @click="openLanguage()"
        >
          <img id="vector" src="../../static/img/singing/Vector.png" />
          Jpan
          <img class="login-logo" id="login-logo" src="./static/image/arrow_bottom.png" />
        </div>
        <div class="lang_lump" v-if="languageDisplay">
          <a
            id="lang_s"
            class="lang"
            style="color: rgb(0, 0, 0)"
            @click="changelang('zh')"
          >
            <img class="login-logo" src="./static/image/869/icon_s.png" />
            简体
            <img
              id="selected_s"
              style="display: block"
              class="login-logo"
              src="./static/image/869/icon_lang_selected.png"
              v-if="recordTheCurrent == 'zh'"
            />
            <img
              id="unselected_s"
              class="login-logo"
              src="./static/image/869/icon_lang_unselect.png"
              style="display: block"
            />
          </a>
          <a id="lang_t" class="lang" @click="changelang('tc')">
            <img class="login-logo" src="./static/image/869/icon_t.png" />
            繁体
            <img
              id="selected_t"
              style="display: block"
              class="login-logo"
              src="./static/image/869/icon_lang_selected.png"
              v-if="recordTheCurrent == 'tc'"
            />
            <img
              id="unselected_t"
              class="login-logo"
              src="./static/image/869/icon_lang_unselect.png"
            />
          </a>
          <a id="lang_e" class="lang" @click="changelang('en')">
            <img class="login-logo" src="./static/image/869/icon_e.png" />
            English
            <img
              id="selected_e"
              style="display: block"
              class="login-logo"
              src="./static/image/869/icon_lang_selected.png"
              v-if="recordTheCurrent == 'en'"
            />
            <img
              id="unselected_e"
              class="login-logo"
              src="./static/image/869/icon_lang_unselect.png"
            />
          </a>
          <a id="lang_j" class="lang" @click="changelang('jp')">
            <img class="login-logo" src="./static/image/869/icon_j.png" />
            Jpan
            <img
              id="selected_j"
              style="display: block"
              class="login-logo"
              src="./static/image/869/icon_lang_selected.png"
              v-if="recordTheCurrent == 'jp'"
            />
            <img
              id="unselected_j"
              class="login-logo"
              src="./static/image/869/icon_lang_unselect.png"
            />
          </a>
        </div>
      </div>
      </div>
 
    </div>
    <div class="singing-content">
      <div class="norow-swipe">
        <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false" indicator-color="white">
          <van-swipe-item>
            <img src="../../static/img/singing/swi1.jpg">
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../static/img/singing/swi2.jpg">
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../static/img/singing/swi3.jpg">
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../static/img/singing/swi4.jpg">
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../static/img/singing/swi5.jpg">
          </van-swipe-item>
          <van-swipe-item>
            <img src="../../static/img/singing/swi6.jpg">
          </van-swipe-item>
        </van-swipe>
        <div class="swipe-info">
          
		  <img v-if="lang=='zh'" src="../../static/img/singing/child_s.png">
		  <img v-if="lang=='tc'" src="../../static/img/singing/child_t.png">
		  <img v-if="lang=='en'" src="../../static/img/singing/child_e.png">
		  <img v-if="lang=='jp'" src="../../static/img/singing/child_j.png">
        
		</div>
      </div>
      <div class="row-swipe">
        <div class="title">{{$t('singing.title1')}}</div>
        <div class="swipe">
          <div class="scroll-container">
            <ul class="scroll-list" ref="list">
				  <li v-for="(item, index) in dataList" :key="index">
					<span>{{item.name}}</span>
					<span style="margin-left: 5%">{{$t('singing.title2')}}{{item.spice}}{{$t('singing.title3')}}</span>
				  </li>
            </ul>
          </div>

        </div>
      </div>
      <div style="margin-top: 20px">
        <img v-if="lang=='zh'" src="../../static/img/singing/photo2_s.jpg">
		<img v-if="lang=='tc'" src="../../static/img/singing/photo2_t.jpg">
		<img v-if="lang=='en'" src="../../static/img/singing/photo2_e.jpg">
		<img v-if="lang=='jp'" src="../../static/img/singing/photo2_j.jpg">
      </div>
      <div >
        <img v-if="lang=='zh'" src="../../static/img/singing/photo3_s.jpg">
        <img v-if="lang=='tc'" src="../../static/img/singing/photo3_t.jpg">
        <img v-if="lang=='en'" src="../../static/img/singing/photo3_e.jpg">
        <img v-if="lang=='jp'" src="../../static/img/singing/photo3_j.jpg">
      </div>
      <div class="singing-amount">
        <div class="amount">
          <div class="left">
            <span>{{$t('singing.title4')}}</span>
            <span>{{juzengzonge}}</span>
          </div>
          <div class="right">
            <span>{{$t('singing.title5')}}</span>
            <span>{{keyongyue}}</span>
          </div>
        </div>
      </div>
      <div class="singing-title">
        <div class="title">
          <span>「{{$t('singing.title6')}}」</span>
        </div>
        <div class="singing-title-content">
          <span>
            {{$t('singing.title7')}}
          </span>
        </div>
      </div>
      <div class="singing-title">
        <div class="title">
          <span>{{$t('singing.title8')}}</span>
        </div>
        <div class="singing-title-content">
          <span>
            {{$t('singing.title9')}}
          </span>
        </div>
      </div>
      <div class="singing-title">
        <div class="title">
          <span>{{$t('singing.title10')}}</span>
        </div>
        <div class="singing-title-content">
          <span>
            {{$t('singing.title11')}}
          </span>
        </div>
      </div>
      <div class="singing-title">
        <div class="title">
          <span>{{$t('singing.title12')}}:</span>
        </div>
        <div class="singing-title-content">
         <p>{{$t('singing.title13')}}</p>
          <p>
            {{$t('singing.title14')}}</p>
          <p>
            {{$t('singing.title15')}}</p>
          <p>
            {{$t('singing.title16')}}</p>
        </div>
      </div>
      <div class="singing-title">
        <div class="title">
          <span>{{$t('singing.title17')}}:</span>
        </div>
        <div class="singing-title-content">
         <p>
          {{$t('singing.title18')}}</p>
          <p>
            {{$t('singing.title19')}}</p>
          <p>
            {{$t('singing.title20')}}</p>
          <p>
            {{$t('singing.title21')}}</p>
        </div>
      </div>
      <div class="singing-title" style="border-top: 1px solid #FFFFFF">
        <div class="title">
          <span>{{$t('singing.title22')}}</span>
          <span>{{$t('singing.title23')}}</span>
          <span>{{$t('singing.title24')}}</span>

        </div>
      </div>
      <div class="singing-list">
        <div class="item">
          <img src="../../static/img/singing/lump_ele_01.png">
          <span>{{$t('singing.title25')}}</span>
          <button @click="juanzeng">{{$t('singing.title28')}}</button>
        </div>
        <div class="item">
          <img src="../../static/img/singing/lump_ele_02.png">
          <span>{{$t('singing.title26')}}</span>
          <button @click="juanzeng">{{$t('singing.title28')}}</button>
        </div>
        <div class="item">
          <img src="../../static/img/singing/lump_ele_03.png">
          <span>{{$t('singing.title27')}}</span>
          <button @click="juanzeng">{{$t('singing.title28')}}</button>
        </div>
      </div>
    </div>
	
  </div>
  
  <div class="pop_lump" id="pop_lump" v-if="topstatus">
  	<div class="con">
  		<div class="close" id="close" @click="topstatus=false">
  			<img src="../../static/img/close.png">
  		</div>
  		<p>{{topsms}}</p>
  	</div>
  </div>
  
  
</template>

<script>
    import $ from 'jquery';
    import {qiandao,juanzeng,showqiandao,Userdata} from "@/api/api";
import { showToast } from "vant";

   export default {
     data(){
       return{
		topstatus:false,
		topsms:'',
         lang: "",
         dataList: [{
           "name":'***am2',
           "spice":"2322"
         },
           {
             "name":'***g0',
             "spice":"3222"
           },
           {
             "name":'***1p3',
             "spice":"12322"
           },
           {
             "name":'***23l',
             "spice":"4509"
           },
           {
             "name":'***89p',
             "spice":"9870"
           },
           {
             "name":'***23k',
             "spice":"8873"
           },
		   
		   
		   {
		     "name":'***la1',
		     "spice":"614"
		   },
		   {
		     "name":'***ia756',
		     "spice":"4522"
		   },
		   {
		     "name":'***ah',
		     "spice":"4840"
		   },
		   {
		     "name":'***gi274',
		     "spice":"1892"
		   },
		   {
		     "name":'***ss74',
		     "spice":"3312"
		   },
		   {
		     "name":'***il',
		     "spice":"2112"
		   },
		   {
		     "name":'***er07',
		     "spice":"3347"
		   },
		   {
		     "name":'***187',
		     "spice":"1170"
		   },
		   {
		     "name":'***ah85',
		     "spice":"3834"
		   },
		   {
		     "name":'***da',
		     "spice":"4569"
		   },
		   {
		     "name":'***ms5',
		     "spice":"4020"
		   },
		   {
		     "name":'***ie30',
		     "spice":"583"
		   },
		   {
		     "name":'***on',
		     "spice":"3766"
		   },
		   {
		     "name":'***ne',
		     "spice":"578"
		   },
		   {
		     "name":'***te808',
		     "spice":"3930"
		   },
		   {
		     "name":'***te811',
		     "spice":"1724"
		   },
		   {
		     "name":'***ia',
		     "spice":"649"
		   },
		   {
		     "name":'***ty356',
		     "spice":"1609"
		   },
		   {
		     "name":'***ah36',
		     "spice":"2900"
		   },
		   {
		     "name":'***am9',
		     "spice":"898"
		   },
		   {
		     "name":'***ke08',
		     "spice":"4371"
		   },
		   {
		     "name":'***am2',
		     "spice":"3572"
		   },
		   {
		     "name":'***ag987',
		     "spice":"3265"
		   },
		   {
		     "name":'***ms979',
		     "spice":"201"
		   },
		   {
		     "name":'***la198',
		     "spice":"1144"
		   },
		   {
		     "name":'***ra',
		     "spice":"4803"
		   },
		   {
		     "name":'***bs2',
		     "spice":"872"
		   },
		   {
		     "name":'***on50',
		     "spice":"1778"
		   },
		   {
		     "name":'***on396',
		     "spice":"1885"
		   },
		   {
		     "name":'***ay10',
		     "spice":"3668"
		   },
		   
		   ],
         scrollInterval: null,
         recordTheCurrent: 'tc',
         languageDisplay: false,
		 juzengzonge:0,
		 keyongyue:0
       }
     },
     mounted() {
       this.lang = sessionStorage.getItem("locale") || 'tc';
       this.startScroll();
       
        $('.badge_heart').click(function(){
	      $('.sign_pop').show()
	    })
	    $('.sign_pop .con .bar .close').click(function(){
	      $('.sign_pop').hide()
	    })
      const self = this;
      $('.sign_pop .sign_btn').click(function(){
	        const day = new Date().getDay()
          self.setSign()
	       // setSign($(this).find('b').text())
	    })
		this.onload()
		this.getSignShow()	
     },
     methods: {
		 
		 onload(){
			 Userdata().then((res) => {
				 console.log(res.data)
				 this.juzengzonge = res.data.url.user.contribute_count
				 this.keyongyue = res.data.url.user.signin_num
			 })
		 },
		 
      //捐赠接口
      juanzeng(){
        juanzeng().then((res) => {
			console.log(res)
			this.topstatus = true
			if(res.data.info==1){
				this.topsms = this.$t('jzcg')
			} else {
				this.topsms = this.$t('jztop')
			}
			
    //       showToast(res.data.info)
		  
        })
      },
          // 签到接口
	    setSign(){
        qiandao().then((result) => {
			if(result.data.status==1){
				// console.log(result)
				// 	if(result.data){
				// 		$('#signSuc').show()
				// 	}else{
				// 		$('#signed').show()
				// 	}
				// 	setTimeout(()=>{
				// 		$('#signSuc').hide()
				// 		$('#signed').hide()
				// 		//window.location.reload()
				// 	},1000)
				// 	this.getSignShow()	
				showToast(this.$t('qdcg'))
				this.getSignShow()
				this.onload()
			} else {
				showToast(this.$t('yjqd'))
			}
				
        })
	    },
      	    // 签到天数展示
            getSignShow() {
    showqiandao().then((result) => {
        const data = result;
		// console.log(data.data)
        if (Array.isArray(data.data.url)) {
            for (let i = 0; i < data.data.url.length; i++) {
                const dayOfWeek =  data.data.url[i].w;
                const sign =  data.data.url[i].sign;

                // 星期几的索引，从1开始
                const dayIndex = dayOfWeek;

                // 根据签到状态设置显示和隐藏
                if (sign === 1) {
                    $(`.sign_pop .ele:eq(${dayIndex - 1}) .money`).hide();
                    $(`.sign_pop .ele:eq(${dayIndex - 1}) .sign`).show();
                } else {
                    $(`.sign_pop .ele:eq(${dayIndex - 1}) .money`).show();
                    $(`.sign_pop .ele:eq(${dayIndex - 1}) .sign`).hide();
                }
            }
        } else {
            console.error('Data is not an array.');
        }
    });
},
      changelang(type) {
        this.recordTheCurrent = type;
        this.languageDisplay = false;
        this.$i18n.locale = type;
        this.lang = type;
        sessionStorage.setItem("locale", type);
      },
      openLanguage () {
      this.languageDisplay = this.languageDisplay ? false : true;
    },
       startScroll() {
         this.scrollInterval = setInterval(() => {
           const list = this.$refs.list;
           if (list){
             const firstItem = list.querySelector('li:first-child');
             list.appendChild(firstItem.cloneNode(true));
             list.removeChild(firstItem);
           }

         }, 2000);
       },
       stopScroll() {
         clearInterval(this.scrollInterval);
       }
     },
     beforeDestroy() {
       this.stopScroll();
     }
   }
</script>

<style lang="scss" scoped>
@import "@/assets/css/qweqwef.scss";
@import "static/css/app.cc8ec.css";
@import "static/css/869_style.css";
    .singing-main{
      width: 100%;
      //height: 100vh;
      background: #1C377C;
      .header{
        //width: 100%;
        height: 45px;
        display: flex;
        justify-content: start;
        padding-top:10px;
        padding-left: 2%;
        .header-left{
          width: 70%;
          display: flex;
          justify-content: start;
		  align-items: center;
          img{
            width: 25px;
            height: 32px;
          }
          span{
            color: #FFFFFF;
            font-size: 15px;
            margin-top: 9px;
            margin-left: 2%;
          }
        }
        .header-right{
          // width: 30%;
        }
      }
      .singing-content{
        .norow-swipe{
          position: relative;
          .my-swipe .van-swipe-item {
            color: #fff;
            font-size: 20px;
            line-height: 150px;
            text-align: center;
            background-color: #39a9ed;
          }
          .swipe-info{
            padding-left: 10%;
            padding-right: 10%;
            position: absolute;
            top: 238px;
          }
        }
        .row-swipe{
          text-align: center;
          height: 140px;
          color: #FFFFFF;
          font-size: 14px;
          padding-top: 20px;
          padding-left:3%;
          padding-right: 3%;
          .title{
            color: #FFFFFF;
            font-size: 14px;
          }
          .swipe{
            margin-top: 10px;
            .scroll-container {
              height: 100px;
              overflow: hidden;
              text-align: left;
              padding-left: 18%;
            }

            .scroll-list {
              margin: 0;
              padding: 0;
              list-style: none;
              animation: scroll 50s linear infinite;
              li{
                margin-top: 20px;
              }
            }

            @keyframes scroll {
              0% {
                transform: translateY(0);
              }
              100% {
                transform: translateY(-100%);
              }
            }
          }
        }
        .singing-amount{
          margin-top: 20px;
          padding-left: 5%;
          padding-right: 5%;
          .amount{
            height: 85px;
            border-radius: 20px;
            background: #FFFFFF;
            display: flex;
            justify-content: start;
            font-size: 14px;
            color: #000000;
            padding-top: 18px;
            .left{
              width: 50%;
              height: 55px;
              border-right: .01rem solid #e6e6e6 ;
              text-align: center;
              //line-height: 50px;
              span{
                display: block;
                margin-top: 10px;
              }
            }
            .right{
              width: 50%;
              height: 55px;
              text-align: center;
              span{
                display: block;
                margin-top: 10px;
              }
            }
          }
        }
        .singing-title{
          margin-top: 20px;
          .title{
            //height: 41px;
            border-bottom: 1px solid #FFFFFF ;
            span{
              display: block;
              padding: .2rem;
              color: white;
              font-weight: 600;
              font-size: 16px;
              line-height: .72rem;
            }
          }
          .singing-title-content {
            span{
              display: block;
              padding: .2rem;
              color: white;
              font-weight: normal;
              font-size: .24rem;
              line-height: .42rem;
            }
            p{
              padding: .2rem;
              color: white;
              font-weight: normal;
              font-size: .24rem;
              line-height: .42rem;
            }
          }
        }
        .singing-list{
          padding-top: 20px;
          padding-left: 5%;
          padding-right: 5%;
          padding-bottom: 50px;
          .item{
            background: #FFFFFF;
            border-radius: 10px;
            display: flex;
            justify-content: start;
            margin-bottom: 10px;
            img{
              width: 80px;
              height: 80px;
            }
            span{
              display: block;
              width: 40%;
              font-size: 16px;
              color: #000000;
              font-weight: 600;
              line-height: 55px;
              margin-left: 5%;
            }
            button{
              text-align: center;
              font-weight: bold;
              font-size: .32rem;
              color: white;
              width: 1.8rem;
              height: .8rem;
              line-height: .8rem!important;
              padding-top: 0;
              margin: 0 auto;
              margin-top: 0.4rem;
              background: linear-gradient(180deg, #274694 0%, #1C377C 100%);
              border: .01rem solid #FFFFFF;
              box-shadow: 0 0.04rem 0.08rem rgba(0, 0, 0, 0.15);
              border-radius: .4rem;
            }
          }
        }
      }

    }
	.pop_lump {
	    position: fixed;
	    width: 100%;
	    height: 100%;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    margin: auto;
	    background: rgba(0,0,0,.7);
	    z-index: 999;
	}
	.pop_lump .con {
	    position: absolute;
	    width: 5rem;
	    height: 3rem;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    margin: auto;
	    background: url(../../static/img/pop_lump_bg.png) 1.3rem 0.5rem white no-repeat;
	    background-size: 2.5rem;
	    border-radius: 0.2rem;
	}
	.pop_lump .con .close {
	    position: absolute;
	    width: 0.5rem;
	    height: 0.5rem;
	    top: 0.15rem;
	    right: 0.15rem;
	}
	.close {
	    float: right;
	    font-size: 21px;
	    font-weight: 700;
	    line-height: 1;
	    color: #000;
	    text-shadow: 0 1px 0 #fff;
	    filter: alpha(opacity=20);
	    opacity: .2;
	}
	.pop_lump .con .close img {
	    width: 0.5rem;
	    height: 0.5rem;
	}
	.pop_lump .con p {
	    text-align: center;
	    padding-left: 0.3rem;
	    padding-right: 0.3rem;
	    padding-top: 2rem;
	    color: black;
	    font-size: .35rem;
		margin: 0 0 10px;
	}
</style>
