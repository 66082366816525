<template>
  <router-view></router-view>
</template>

<script setup>
</script>

<style>
  /*去掉底部导航栏顶部白边*/
  .van-hairline--top-bottom:after{
    border: unset !important;
  }
  /* 设置Dialog的样式 */
 /* .van-dialog__content--isolated {
  background-color: #FFEDED !important;
  } */
   .el-popper{
    background: rgba(0, 0, 0, 0.8) !important;
     z-index: 999999999999 !important;
  }
  .el-select-dropdown {
    color: var(--el-color-primary);
    font-weight: 700;
    background: rgba(0, 0, 0, 0.8) !important;
    z-index: 99999999999 !important;
  }
  .el-select-dropdown__item.selected {
    color: var(--el-color-primary);
    font-weight: 700;
    background: rgba(0, 0, 0, 0.8) !important;
    z-index: 99999999999 !important;
  }
  .name img {
	width: 26px;
	height: 26px;
}

</style>
