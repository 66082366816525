export default {
	home: {
		Pionexpro:'Nuropro',
		ARTIFICIAL:'ARTIFICIAL INTELLIGENCE TECHNOLOGY, PAVING THE FUTURE OF TRADING',
		PreferentialActivities: 'Preferential Activities',
		Deposit: 'Deposit',
		ProductIntroduction: 'Product Introduction',
		OnlineCustomerService: 'Online Customer Service',
		HotProducts: 'Hot Products',
		Thailand: 'Thailand',
		canada: 'canada',
		Australia: 'Australia',
		NewYork: 'NewYork',
		Singapore: 'Singapore',
		England: 'England',
		Japan: 'Japan',
		India: 'India',
		ljcj:'Draw Now',
		czcj:'Stored value lottery',
		ckgd:'see more',
		jzlb:'Donation list',
		jcyx:'Wonderful game',
		ax_tips:'Your long-term support plays a vital role in promoting the successful development and increasing influence of the Yitong project. This ongoing support has a positive impact on social well-being and children\'s welfare, providing them with better life and educational opportunities.',
		"768194-0": "Experience Bonus",
"768194-1": "Simplified",
"768194-2": "Traditional",
"768194-3": "Promote the traditional virtues of charity, promote social progress, join hands with Duo Duo Zhi Li to create an online digital entertainment platform, daily tasks can receive up to 257,760 bonus, wish you a happy life~",
"768194-4": "Singapore Da Cai",
"768194-5": "4D Lottery, Play for Big Prizes",
"768194-6": "Enter",
"768194-7": "Ten Thousand Digit Tickets",
"768194-8": "Treasure Hunt Grand Action",
"768194-9": "Duo Duo Lottery",
"768194-10": "Lucky Jackpot, Waiting for You to Win",
"768194-11": "Thanks to every Yitong supporter who has been donating for more than 18 months!",
"768194-12": "Donate",
"768194-13": "Wallet",
"768194-14": "Love Contribution",
"985138-0": "Log In",
"985138-1": "Simp",
"985138-2": "Trad",
"985138-3": "Spread Love and Fill Hearts with Hope",
"985138-4": "Welcome",
"985138-5": "Sign Up",
"985138-6": "Enter Account",
"985138-7": "Enter Password",
"985138-8": "Log In Now",
"063348-0": "Sign Up",
"063348-1": "Simp",
"063348-2": "Trad",
"063348-3": "Spread Love and Fill Hearts with Hope",
"063348-4": "Welcome",
"063348-5": "Log In",
"063348-6": "Sign Up",
"063348-7": "Please enter 6-20 letters, numbers or a combination",
"063348-8": "Please enter 6-16 letters and numbers",
"063348-9": "Please enter the invitation code",
"063348-10": "Please fill in your agent's invitation code",
"063348-11": "I am over 18 years old and agree to accept the",
"063348-12": "Registration Terms",
"063348-13": "Sign Up Now",
"063348-80":"confirmPassword"
	},
	tab2: {
		InvestmentRecord: 'Financial Record',
		title1: "Ušün Vanguard",
		title2: "BlackRock AeokAs La: aonuầuusẽưna:Isuño?-Blockdit tũuuwacwasśu aÍnнรัuนัnอ่าuแล:นักเปียนทีมีผู้ชังาบ1ล้านคบลองใช้แพลตฟอร์มนีเพือได้ไอเดียใหม่ๆแล้วอาจพบว่าสังคมนีเหมาะกั บคนเช่นคุณ.",
		title3: "-Blockdit tũuuwacwasśu aÍnнรัuนัnอ่าu แล:นักเปียนทีมีผู้ชังาบ1ล้านคบลองใช้แพลตฟอร์มนีเพือได้ไอเดียใหม่ๆแล้วอาจพบว่าสังคมนีเหมาะกั บคนเช่นคุณ.",
		title4: "-BlackRock is an investment management company based in the United States, founded in 1988 by a group of investors and businessmen. It has grown to become one of the largest investment management companies in the world, with assets under management of $234 billion. BlackRock provides investment services to clients in over 100 countries and is listed on the S&P 500 index as “BLK”.",
		title5: "It raised capital in",
		title6: "the New York Stock Exchange in 1999. The total assets under management at the time of its founding were $4.9 billion, which has since increased to $234 billion, or nearly 48 times. BlackRock is the largest investment management company in the world when measured by assets under management (AUM).",
		title7: "1. BlackRock has assets under management valued at $234 billion",
		title8: "2. Vanguard Group has assets under management valued at $183 billion",
		title9: "3. UBS Group AG has assets under management valued at $105 billion. It is an investment management company that receives investment from clients under the management of BlackRock.",
		title10: "- Aqu 52%",
		title11: "-cnsnansHüB1%",
		title12: "- tōuao 7%",
		title13: "- ãunšwúóu 9 10%",
		title14: "While following the group of clients from the assets under management of BlackRock",
		title15: "-70% are clients in North America",
		title16: "- 25% are clients in Europe and Africa",
		title17: "-5% are clients in Asia",
		title18: "In summary, BlackRock focuses on investing in stocks and its main clients are in North America, while currently providing services to investors in over 100 countries worldwide. BlackRock is also one of the companies included in the S&P 500 index as “BLK”.",
		title19: "Holding a major stake in companies",
		title20: "BlackRock holds a major stake in several global companies, such as",
		title21: "- Holding 1,069 million shares of Apple, valued at $4.1 trillion",
		title22: "- Holding 516 million shares of Microsoft, valued at $3.4 trillion",
		title23: "- Holding 27 million shares of Amazon.com, valued at $2.6 trillion",
		title24: "- Holding 158 million shares of Facebook, valued at $1.3 trillion",
		title25: "In the case of Apple, BlackRock is one of the top 2 largest shareholders, making one of the co-founders of BlackRock a member of Apple's board of directors. Additionally, BlackRock's performance has been as follows:",
		title26: "In 2017, it had a revenue of $375 billion and a net income of $149 billion",
		title27: "In 2018, it had a revenue of $426 billion and a net income of $129 billion",
		title28: "In 2019, it had a revenue of $463 billion and a net income of $135 billion",
		title29: "As of the end of the fiscal year, the market value of BlackRock's assets was $73.2 trillion",
		title30: "It manages more than $21 trillion, up from $1.1 trillion in 2010",
		title31: "BlackRock's profit surpasses that of commercial banks."
	},
	tab4: {
		Loginnow: 'Log in now',
		RegisterNow: 'Register Now',
		Withdrawcash: 'Withdraw cash',
		deposit: 'deposit',
		profitAndLoss: "Today's profit and loss",
		ProductIntroduction: 'Product Introduction',
		FundDetails: 'Fund Details',
		InvestmentRecord: 'Investment Record',
		PreferentialActivities: 'Preferential Activities',
		SecurityCenter: 'Security Center',
		Contactcustomerservice: 'Contact customer service',
		Setup: 'Setup',
		shezhiyhk: 'Please set up a bank card first',
		shezhitxmm: 'Please set a withdrawal password first'
	},
	login: {
		Loginnow: 'Log in now',
		QuickRegistration: 'Quick Registration',
		Pleaseenteryourusernamephonenumber: 'Please enter your username/phone number',
		Pleaseenterapassword: 'Please enter a password'
	},
	registe: {
		RegisterNow: 'Register Now',
		Logintoanexistingaccount: 'Login to an existing account',
	},
	shezhi: {
		languagesettings: 'language settings',
		Switchaccount: 'Switch account'
	},
	yuyanshezhi: {
		languagesettings: 'language settings'
	},
	editbank: {
		Addabankcard: 'Add a bank card',
		RealName: 'Real Name',
		BankCardNumber: 'Bank Card Number',
		BankAddress: 'Bank Address',
		Pleaseenteryourrealname: 'Please enter your realname',
		Pleaseenteryourbankcardnumber: 'Please enter your bank card number',
		Pleaseenterthebankaddress: 'Please enter the bank address'
	},
	remen: {
		AccountCurrency: 'Account Currency',
		Recharge: 'Recharge',
		BasicsROOM: 'Basics ROOM',
		GrandmasterROOM: 'Grandmaster ROOM',
		expertROOM: 'expert ROOM',
		siperVIP: 'siper VIP',
		Singleminimumpurchase: 'Single minimum purchase',
		Entertheminimumamount: 'Enter the minimum amount',
		Numberoftransactions: 'Number of transactions',
		people: 'people'
	},
	jiaoyizhongxin: {
		TransactionHistory: 'Transaction History',
		v2_until: 'Until.',
		v2_getting: 'Receptivity',
		User: 'User',
		Numberofrounds: 'Number of rounds',
		TradeCategory: 'Trade Category',
		SingleRoundTrading: 'Single Round Trading',
		Operation: 'Operation',
		v2_changui: 'market',
		v2_teshu: 'Special',
		v2_shuwei: 'Numbers.',
		v2_duo: 'Do more.',
		v2_kong: "short",
		v2_dan: 'Command.',
		v2_shuang: 'Double.',
		v2_jiyang: "Increase",
		v2_jiyin: "Shorter",
		v2_teshu_4: 'General Election of the Tiger Star',
		v2_teshu_5: 'Direct selection',
		v2_teshu_6: 'Pair selection',
		v2_1: 'One hundred and ten',
		v2_2: 'Low One Hundred High One Hundred',
		v2_3: 'Hundred high.',
		v2_4: 'Hundreds of lowest levels.',
		v2_5: 'Ten high.',
		v2_6: 'Ten low sounds',
		Buy: 'Buy',
		gendanbt: 'Buy',
		di: 'Round',
		lun: 'wheel',
		alertqdgd: 'Confirm the documentary?',
		queding: "OK",
		quxiao: "Cancel",
		v2_duodan: "Multiple orders",
		v2_kongdan: "Empty order",
		v2_duoshuang: "Duoduo",
		v2_kongshuang: "Empty double"
	},
	tabbar: {
		Home: 'Home',
		Recharge: 'Recharge',
		Trend: 'Trend',
		My: 'My',
		kefu:'Contact'
	},
	srdlmm: 'Enter login password',
	tixianmima: "Withdraw Password",
	denglumima: "Login Password",
	zhong: "中",
	anquandengji: "Security Level",
	anquanzhongxin: "Security Center",
	zhanghaowuyanzhengfangshi: "The account has no verification method, please forget the old password",
	lianxikefu: "Contact customer service",
	xindenglumimaguize: "Please enter a combination of 6-16 digits and letters for the new login password",
	xinmima: "New Password",
	querenxinmima: "Confirm new password",
	jiumima: "Old password",
	xiugaidenglumima: "Modify login password",
	shurujiumima: "Please enter the old password",
	xiugaitixianmima: "Modify withdrawal password",
	yuanmimabuyongshuru: "Original password, no need to enter the first setting",
	qingshuruxinmima: "Please enter your new password",
	queding: "OK",
	tianjiayinhangka: "Add a bank card",
	profit: {
		biaoti: 'Preferential Activities',
		name: '7วัน Financial Management',
		jine: 'amount',
		shouru: 'income',
		cunkuanshijian: 'Deposit time',
		daoqi: 'Expire date',
		cunkuanjine: 'Deposit amount',
		cunkuan: 'Deposit now',
		shurujine: 'Enter amount',
		goumai: 'Buy',
		quxiao: 'Cancel',
	},
	fund: {
		biaoti: 'Fund details',
		tikuan: 'Withdraw money',
		zhuangtai: 'state',
		shijian: 'time',
		nomore: 'No more information',
	},
	txmoney: {
		Withdrawcash: 'Withdraw cash',
		AmountofCashWithdrawal: 'Amount of Cash Withdrawal',
		Withdrawcashamount: 'Withdraw cash amount',
		WithdrawPassword: 'Withdraw Password',
		Pleaseenterthewithdrawalamount: 'Please enter the withdrawal amount',
		Pleaseenteryourwithdrawalcode: 'Please enter your withdrawal code'
	},
	touzijilu: {
		biaoti: 'Investment Record',
		lunshu: 'Number of rounds',
		danbijine: 'Single investment amount',
		huoli: 'Profit amount',
		leixing: 'Transaction Type',
		zhuangyai: 'State',
		shijian: 'time',
		Pendingearnings: 'Pending earnings',
		Investmentsuccess: 'Investment success',
		investmentfailure: 'investment failure'
	},
	game: {
		game1: 'Protect the Ocean',
		game2: 'Distance',
		game3: 'Round',
		game4: 'Deadline',
		game5: 'Small',
		game6: 'Even',
		game7: 'Sum',
		game8: 'Tie:',
		game9: 'Lottery Results',
		game10: 'Trend Chart',
		game11: 'Size Trend',
		game12: 'Odd/Even Trend',
		game13: 'Big',
		game14: 'Single',
		game15: 'Number of Bets in this Round',
		game16: 'Bets',
		game17: 'View Bets in this Round',
		game18: 'View Tracking Information',
		game19: 'Betting in Progress for Round',
		game20: 'Tracking',
		game21: 'Set to automatically stop tracking after winning within the specified number of rounds',
		game22: 'If not won, stop tracking after playing the set number of rounds',
		game23: 'Select Number of Rounds:',
		game24: 'Pre-purchase',
		game25: 'Pre-purchase Total:',
		game26: 'Mixed',
		game27: 'Special Number',
		game28: 'Pre-purchase',
		game29: 'Gameplay:',
		game30: 'Big Single:',
		game31: 'Big Even:',
		game32: 'Small Single:',
		game33: 'Small Even:',
		game34: 'Extreme Big:',
		game35: 'Extreme Small:',
		game36: 'Triple:',
		game37: 'Red Wave:',
		game38: 'Green Wave:',
		game39: 'Blue Wave:',
		game40: 'Select gameplay, click to purchase with amount:',
		game41: 'Purchase Amount:',
		game42: 'Tracking:',
		game43: 'Purchase:',
		game44: 'Reset:',
		game45: 'Back:',
		game46: 'Buy-in:',
	},
	kefu:{
		title:'FAQ',
		t1:'Contact Us',
		q1:'1. How to deposit?',
		q1a:'A: Contact our customer service and get the latest bank account information. Transfer your fund through online banking and submit the proof of payment to our customer service for verification. Once the customer service agent verified the deposit, refresh the page and you will see the updated balance.',
		q2:'2. What should I do if I forget my password?',
		q2a:'A: Please provide the corresponding information to the customer service in time, and the system will reset a new password. To ensure account security, it is recommended that the user change the password immediately after logging in.',
		q3:'3. How to withdraw the prize from my account?',
		q3a:'A: Before you withdraw, make sure you submitted the correct account information. In order to prevent a small number of players from using credit cards to cash out and money laundering, and to protect the safety of normal players\' funds, this site makes the following provision:',
		q3b:'(1) All player need to reach a certain betting amount before the withdrawal can be processed normally.',
		q3c:'(2) Any suspicious betting will be reviewed.',
		q4:'4. How many times can I withdraw in a day?',
		q4a:'A: Starry does not limit the number of withdrawals.',
		q5:'5. Is there a fee for withdrawal?',
		q5a:'A: You can submit a withdrawal request once you meet the withdrawal requirements. All withdrawal fees will be borne by Starry.',
		q6:'6. Where can I find company information?',
		q6a:'Company official website address：',
		kfxt:'Customer service little T',
		t001:'Hello! Intelligent customer service is at your service~',
	},
	my:{
		t1:'balance',
		t2:'wallet',
		t3:'records',
		t4:'Information',
		t5:'Password',
		t6:'Modify Pay Pass',
		t7:'Exit',
	},
	wallet: {
		t1: 'Account Balance',
		t2: 'Available Balance',
		t3: 'frozen amount',
		t4: 'Deposit',
		t5: 'Withdraw',
		t6: 'Bank Account',
		t7: 'My funds flow',
	},
	cash:{
		t1:'Withdrawal application',
		t2:'Maximum withdrawal：',
		t3:'Pay Password',
		t4:'Withdrawal Amount',
		t5:'Withdrawal Fee',
		t6:'Receiving Amount',
		t7:'Name',
		t8:'Bank name',
		t9:'Bank Account',
		t10:'Comment',
	},
	cardInfo:{
		t1:'Bank Account Manage',
		t2:'My Bank Account',
		t3:'If your Bank Account is restricted, please contact customer service',
		t4:'Tips：',
		t5:'1. Only one Bank Account can be binded',
		t6:'2. You can claim withdrawal to your bank account after binding via the bank card',
		t7:'withdraw ',
	},
	recharge:{
		t1:'Account Deposit',
		t2:'Deposit',
		t3:'Minimum Deposit amount$100',
		t4:'Account username',
		t5:'payment method',
		t6:'Exchange Rate',
		t7:'prompt',
		t8:'1、Please confirm your account. The minimum deposit amount is：$100',
		t9:'2、Please choose the payment method according to the payment method provided above',
		t10:'3、When paying, please note the order number you paid for submission and review. After successful submission, please wait for confirmation',
	},
	common_text:{
		tj:'submit',
		RM:'RM',
		EUR:'EUR',
		USD:'USD',
		JPY:'JPY',
	},
	singing:{
		"logo_title": "Love and Dedication",
		"title1": "Thank you to all the dedicated supporters of Yitong who have been donating for over 18 months!",
		"title2": "Donation",
		"title3": "Assistance Fund",
		"title4": "My Total Charitable Donations",
		"title5": "Available Donation Amount",
		"title6": "Love and Dedication",
		"title7": "While operating sustainable and successful businesses, the Assistance Fund also strives to bring positive impact to the country and community where we operate. The Assistance Fund actively supports the development and growth of the nation and community, fulfills corporate social responsibilities through participation in charitable activities, and aims to build a better future for the next generation. With this commitment, the Assistance Fund was established to give back to society. It operates independently from Yitong and has its own board of trustees, focusing on organizing charitable activities that cultivate positive outlooks on life, education, and employment for young people, aiming to help the next generation improve their living standards and create a more inclusive and harmonious environment, encouraging young people to become responsible citizens. Looking ahead, the Assistance Fund will continue to support and promote the construction of a more inclusive and harmonious society, and carry forward projects that embody humanitarian and philanthropic spirits, serving and assisting the poor and those in need, facing poverty and disasters together, overcoming difficulties together. The group is particularly concerned about the situation and well-being of children, and pays attention to gender equality and environmental protection issues. To date, the group has donated tens of millions of dollars to the Assistance Fund, and the donated funds continue to grow. Each of your contributions will help someone in need who is far away. The main assistance activities of the group currently include:",
		"title8": "Hope Primary School Program",
		"title9": "2. African Children, aimed at helping African children meet their basic living needs, including donating food, medicine, anti-malaria vaccines, mosquito nets, and other daily necessities. The COVID-19 pandemic in 2019 has caused a global crisis and disrupted the lives of children in various parts of Africa. The long-standing inequality has dealt the heaviest blow to children living in the poorest countries and communities. The African Children Assistance Fund of the group is continuously accumulating.",
		"title10": "Hearing Impaired Children",
		"title11": "3. Since the launch of the Hearing Impaired Children program, every year, the participating hearing-impaired children have entered the 5th School of the St. Joseph Diocese through this program and joined the “inclusive classroom” with other children. After completing 3 years of study in the inclusive classroom taught by one hearing teacher and one deaf teaching assistant, the first batch of 5 children graduated from kindergarten in July 2021. Given the successful trial of this program in the past 3 years, the Macau Diocese, as the school's operating entity, has now made the program a continuous practice project and will ensure its continuous implementation, allowing hearing-impaired children participating in the program to receive primary education in schools and hopefully continue to receive secondary education. Learn more about the development of hearing-impaired children, understand the growth stages they will go through, and how to help them achieve this growth and development. The Hearing Impaired Children Assistance Fund of the group is continuously accumulating.",
		"title12": "Daily Assistance Explanation",
		"title13": "1. Member users can receive assistance funds once a day.",
		"title14": "2. Daily assistance is non-profit and aims to give back to society.",
		"title15": "3. The assistance funds obtained daily cannot be withdrawn or traded.",
		"title16": "4. The right to use the assistance funds obtained daily belongs to the account owner and can only be used for charitable donation projects. Donations can be made to relevant charitable projects through the group.",
		"title17": "Charitable Assistance Fund Explanation",
		"title18": "1. For every profit made by users in the game, the platform will additionally pay 0.01% of the winning amount to the user's donation assistance fund. The amount is not capped.",
		"title19": "2. The Charitable Assistance Fund is non-profit and aims to give back to society. It cannot be used for platform profit.",
		"title20": "3. The donated assistance funds obtained from the Charitable Assistance Fund cannot be withdrawn or traded.",
		"title21": "4. The right to use the donated assistance funds obtained from the Charitable Assistance Fund belongs to the account owner and can only be used for charitable donation projects. Donations can be made to relevant charitable projects through the group.",
		"title22": "〈Join Our Program〉",
		"title23": "〈Redraw Tomorrow's Dreams〉",
		"title24": "〈Join Hands for Children〉",
		"title25": "Hope Primary School",
		"title26": "Assist African Children",
		"title27": "Hearing Impaired Children",
		"title28": "Donate"
	},
	loginpwd_new: {
		title1: "Set Fund Password",
		title2: "Old Password",
		title3: "Old Password (No need to fill in for the first time setting)",
		title4: "New Security Password",
		title5: "New Password",
		title6: "Confirm Security Password",
		title7: "Confirm Password",
		title8: "Verification Code",
		title9: "Save",
		title10: "For assistance,",
		title11: "please contact customer service",
		title12: "Please enter password and verification code"
	},
	account:{
		title1: "Transaction Records",
		title2: "Displaying only 1 month of transaction records. For more information, please contact customer service.",
		title3: "Previous Page",
		title4: "Next Page",
		title5: "Withdraw",
		title6: "Bet",
		title7: "Profit",
		title8: "Deposit",
		title9: "Today",
		title10: "Yesterday",
		title11: "Last 7 Days",
		title12: "This Month"
	},
	Lottery:{
		txt1: "Account information",
		txt2: "Name",
		txt3: "Set a personalized nickname",
		txt4: "save",
		txt5: "hint",
		txt6: "The name and the receiving bank card must be consistent, otherwise the withdrawal will fail.",
		txt7: "Please keep your account password safe and do not lend it to others for use.",
		txt8: "Lottery results",
		txt9: "refresh",
		txt10: "Number",
		txt11: "size",
		txt12: "odd and even",
		txt13: "sum",
		txt14: "Small",
		txt15: "pair",
		txt16: "No data",
		txt17: "Recharge lottery",
		txt18: "You currently have no lottery entries",
		txt19: "Today's remaining times",
		txt20: "Other winners list",
		txt21: "Prize list",
		txt22: "Rule description",
		txt23: "The number of red envelope draws is updated daily and is not cumulative.",
		txt24: "The amount of the red envelope activated by the lottery can be collected.",
		txt25: "The final interpretation right of the red envelope lottery belongs to the company.",
		txt26: "If the amount of the lottery red envelope exceeds the 1,000 free activation limit, it can be withdrawn only after activation according to regulations.",
		txt27: "Congratulations on winning the bonus",
	},
	jzcg:'Donation successful',
	jztop:'There is no amount to donate',
	qdcg:'Successful check-in',
	yjqd:'Signed in',

	mqmyjh:'You currently have no lottery entries',
	gdzlxm:'More support projects',
	gdtop:'Stay tuned for more support projects',
	xianzhi:'Warm reminder: Amount limit',

	yaoqingma:'Invitation code',
	yiji:'Level 1',
	erji:'Level 2',
	sanji:'Level 3',
	fzcg:'Replicating Success',
	fzsb:'copy failed',

	xgdlmm:'Change login password',
	gxzj:'Congratulations on winning',
	zjjj:'bonus',

	zcmmts:'The passwords entered twice are inconsistent',


	newtext1:'Daily check-in',
	newtext2:'Monday',
	newtext3:'Tuesday',
	newtext4:'Wednesday',
	newtext5:'Thursday',
	newtext6:'Friday',
	newtext7:'Saturday',
	newtext8:'Sunday',
	newtext9:'Sign in',

	newtext10:'message',
	newtext11:'Confirm buy',
	newtext12:'Cancel',
	newtext13:'chosen',
	newtext14:'Note the gameplay, total amount:',

	newtext15:'Chow Tai Fook Necklace',
	newtext16:'rice cooker',
	newtext17:'Lucky red envelope',
	newtext18:'Ten-day trip to Japan',
	newtext19:'Rolex watch',
	newtext20:'Lucky red envelope',

	newtext21:'Enter verification code',


	newtext22:'Personal center',

	newtext23:'User Center',
	newtext24:'My wallet',
	newtext25:'Account Management',
	newtext26:'Security under security',

	newtext27:'type',
	newtext28:'time',

	newtext29:'Leave a message',
	newtext30:'Withdrawal amount',

	newtext31:'Account Balance',
	newtext32:'record',

	newlogin:{
		text1:'Nuro pro.',
		text2:'Artificial Intelligence',
		text3:'Technology',
		text4:'Paving the Future of Trading.',
		text5:'Select A Wallet',
		text6:'Simulated Trading',
		text7: 'Please install MetaMask or any Ethereum Extension Wallet',
		text8:'Welcome to Intelligence',
		text9:'Quantification AI System',
		text10:'Paving the Future of Trading.',
		text11:'Your Current Address',
		text12:'Official Trading',
		text13:'Simulated Trading'
	},
	newtabbar: {
		Home: 'Home',
		Quantization:'Quantization',
		Assets:'Assets'
	},
}
