export default {
	home: {
		PreferentialActivities: 'Khuyến mại',
		Deposit: 'nạp tiền',
		ProductIntroduction: 'Mô tả Sản phẩm',
		OnlineCustomerService: 'dịch vụ trực tuyến',
		HotProducts: 'Sản phẩm phổ biến',
		Thailand: 'Thailand',
		canada: 'canada',
		Australia: 'Australia',
		NewYork: 'NewYork',
		Singapore: 'Singapore',
		England: 'England',
		Japan: 'Japan',
		India: 'India'
	},
	tab2: {
		InvestmentRecord: 'Hồ sơ tài chính',
		title1: "Ušün Vanguard",
		title2: "BlackRock AeokAs La: aonuầuusẽưna:Isuño?-Blockdit tũuuwacwasśu aÍnнรัuนัnอ่าuแล:นักเปียนทีมีผู้ชังาบ1ล้านคบลองใช้แพลตฟอร์มนีเพือได้ไอเดียใหม่ๆแล้วอาจพบว่าสังคมนีเหมาะกั บคนเช่นคุณ.",
		title3: "-Blockdit tũuuwacwasśu aÍnнรัuนัnอ่าu แล:นักเปียนทีมีผู้ชังาบ1ล้านคบลองใช้แพลตฟอร์มนีเพือได้ไอเดียใหม่ๆแล้วอาจพบว่าสังคมนีเหมาะกั บคนเช่นคุณ.",
		title4: "-BlackRock là một công ty quản lý đầu tư có trụ sở tại Hoa Kỳ, được thành lập vào năm 1988 bởi một nhóm nhà đầu tư và doanh nhân. Nó đã phát triển thành một trong những công ty quản lý đầu tư lớn nhất thế giới, với tài sản quản lý lên đến 234 tỷ đô la. BlackRock cung cấp dịch vụ đầu tư cho khách hàng ở hơn 100 quốc gia và được niêm yết trên chỉ số S&P 500 với mã “BLK”.",
		title5: "Nó huy động vốn vào",
		title6: "Sàn giao dịch chứng khoán New York vào năm 1999. Tổng tài sản quản lý vào thời điểm thành lập là 4.9 tỷ đô la, đã tăng lên 234 tỷ đô la, gần 48 lần. BlackRock là công ty quản lý đầu tư lớn nhất thế giới khi đo lường theo tài sản quản lý (AUM).",
		title7: "1. BlackRock có tài sản quản lý trị giá 234 tỷ đô la",
		title8: "2. Nhóm Vanguard có tài sản quản lý trị giá 183 tỷ đô la",
		title9: "3. UBS Group AG có tài sản quản lý trị giá 105 tỷ đô la. Đây là một công ty quản lý đầu tư nhận đầu tư từ khách hàng dưới sự quản lý của BlackRock.",
		title10: "- Aqu 52%",
		title11: "-cnsnansHüB1%",
		title12: "- tōuao 7%",
		title13: "- ãunšwúóu 9 10%",
		title14: "Trong khi theo dõi nhóm khách hàng từ tài sản quản lý của BlackRock",
		title15: "-70% là khách hàng ở Bắc Mỹ",
		title16: "- 25% là khách hàng ở Châu Âu và Châu Phi",
		title17: "-5% là khách hàng ở Châu Á",
		title18: "Tóm lại, BlackRock tập trung vào đầu tư vào cổ phiếu và khách hàng chính của họ ở Bắc Mỹ, đồng thời hiện đang cung cấp dịch vụ cho các nhà đầu tư ở hơn 100 quốc gia trên toàn thế giới. BlackRock cũng là một trong những công ty được bao gồm trong chỉ số S&P 500 với tên gọi “BLK”.",
		title19: "Giữ cổ phần lớn trong các công ty",
		title20: "BlackRock giữ cổ phần lớn trong một số công ty toàn cầu, như",
		title21: "- Giữ 1.069 triệu cổ phiếu của Apple, trị giá 4.1 nghìn tỷ đô la",
		title22: "- Giữ 516 triệu cổ phiếu của Microsoft, trị giá 3.4 nghìn tỷ đô la",
		title23: "- Giữ 27 triệu cổ phiếu của Amazon.com, trị giá 2.6 nghìn tỷ đô la",
		title24: "- Giữ 158 triệu cổ phiếu của Facebook, trị giá 1.3 nghìn tỷ đô la",
		title25: "Trong trường hợp của Apple, BlackRock là một trong hai cổ đông lớn nhất, khiến một trong những người sáng lập BlackRock trở thành thành viên của Hội đồng quản trị Apple. Ngoài ra, hiệu suất của BlackRock như sau:",
		title26: "Năm 2017, doanh thu đạt 375 tỷ đô la và lợi nhuận ròng đạt 149 tỷ đô la",
		title27: "Năm 2018, doanh thu đạt 426 tỷ đô la và lợi nhuận ròng đạt 129 tỷ đô la",
		title28: "Năm 2019, doanh thu đạt 463 tỷ đô la và lợi nhuận ròng đạt 135 tỷ đô la",
		title29: "Đến cuối năm tài chính, giá trị thị trường của tài sản BlackRock là 73.2 nghìn tỷ đô la",
		title30: "Nó quản lý hơn 21 nghìn tỷ đô la, tăng từ 1.1 nghìn tỷ đô la vào năm 2010",
		title31: "Lợi nhuận của BlackRock vượt qua lợi nhuận của ngân hàng thương mại."
	},
	tab4: {
		Loginnow: 'đăng nhập ngay lập tức',
		RegisterNow: 'Đăng ký ngay',
		Withdrawcash: 'rút',
		deposit: 'Tiền gửi',
		profitAndLoss: "Lợi nhuận và lỗ hôm nay",
		ProductIntroduction: 'Mô tả Sản phẩm',
		FundDetails: 'Chi tiết tài trợ',
		InvestmentRecord: 'hồ sơ đầu tư',
		PreferentialActivities: 'Khuyến mại',
		SecurityCenter: 'Trung tâm bảo vệ',
		Contactcustomerservice: 'liên hệ với dịch vụ khách hàng',
		Setup: 'cài đặt',
		shezhiyhk: 'Vui lòng thiết lập thẻ ngân hàng trước',
		shezhitxmm: 'Vui lòng đặt mật khẩu rút tiền trước'
	},
	login: {
		Loginnow: 'đăng nhập ngay lập tức',
		QuickRegistration: 'đăng kí nhanh',
		Pleaseenteryourusernamephonenumber: 'Vui lòng nhập tên người dùng / số điện thoại di động',
		Pleaseenterapassword: 'Xin vui lòng nhập mật khẩu'
	},
	registe: {
		RegisterNow: 'Đăng ký ngay',
		Logintoanexistingaccount: 'Đăng nhập vào tài khoản hiện có',
	},
	shezhi: {
		languagesettings: 'cài đặt ngôn ngữ',
		Switchaccount: 'chuyển tài khoản'
	},
	yuyanshezhi: {
		languagesettings: 'cài đặt ngôn ngữ'
	},
	editbank: {
		Addabankcard: 'Thêm thẻ ngân hàng',
		RealName: 'tên thật',
		BankCardNumber: 'Vui lòng nhập số thẻ ngân hàng',
		BankAddress: 'địa chỉ ngân hàng',
		Pleaseenteryourrealname: 'vui lòng nhập tên thật của bạn',
		Pleaseenteryourbankcardnumber: 'Số thẻ ngân hàng',
		Pleaseenterthebankaddress: 'Vui lòng nhập địa chỉ ngân hàng'
	},
	remen: {
		AccountCurrency: 'Đơn vị tiền tệ của tài khoản',
		Recharge: 'nạp điện',
		BasicsROOM: 'Quản lý tài chính cơ bản',
		GrandmasterROOM: 'Tài chính Thạc sĩ',
		expertROOM: 'Chuyên gia tài chính',
		siperVIP: 'Super et VIP',
		Singleminimumpurchase: 'Một lần mua tối thiểu',
		Entertheminimumamount: 'Nhập số tiền tối thiểu',
		Numberoftransactions: 'Số lượng giao dịch',
		people: 'Mọi người'
	},
	jiaoyizhongxin: {
		TransactionHistory: 'Ghi lại giao dịch',
		v2_until: 'cho đến khi',
		v2_getting: 'Đang bắt',
		User: 'người sử dụng',
		Numberofrounds: 'số vòng',
		TradeCategory: 'Loại giao dịch',
		SingleRoundTrading: 'một vòng giao dịch',
		Operation: 'vận hành',
		v2_changui: 'Thị trường',
		v2_teshu: 'Đặc biệt',
		v2_shuwei: 'Những con số.',
		v2_duo: 'làm thêm',
		v2_kong: "Ngắn",
		v2_dan: 'Đặt hàng',
		v2_shuang: 'Làm gấp đôi',
		v2_jiyang: "Cực dương",
		v2_jiyin: "Cực kỳ Âm",
		v2_teshu_4: 'Báo bầu cử',
		v2_teshu_5: 'Chọn trực tiếp',
		v2_teshu_6: 'Chọn một cặp',
		v2_1: 'Trăm mười cao',
		v2_2: 'Trăm mười thấp',
		v2_3: 'Trăm cao',
		v2_4: 'Hàng trăm mức thấp',
		v2_5: 'Mười cao',
		v2_6: 'Mười mức thấp',
		Buy: 'mua',
		gendanbt: 'Phim tài liệu',
		di: 'đầu tiên',
		lun: 'bánh xe',
		alertqdgd: 'Xác nhận đơn đặt hàng？',
		queding: "Chắc chắn rồi",
		quxiao: "Hủy bỏ",
		v2_duodan: "Nhiều đơn đặt hàng",
		v2_kongdan: "đơn đặt hàng trống",
		v2_duoshuang: "Duoshuang",
		v2_kongshuang: "trống kép"
	},
	tabbar: {
		Home: 'trang đầu',
		Recharge: 'nạp điện',
		Trend: 'xu hướng',
		My: 'của tôi'
	},
	srdlmm: 'Nhập mật khẩu đăng nhập',
	tixianmima: "Mật khẩu rút tiền",
	denglumima: "mật khẩu đăng nhập",
	zhong: "ở giữa",
	anquandengji: "Mức độ bảo mật",
	anquanzhongxin: "Trung tâm bảo vệ",
	zhanghaowuyanzhengfangshi: "Không có phương pháp xác minh cho tài khoản, vui lòng quên mật khẩu cũ",
	lianxikefu: "liên hệ với dịch vụ khách hàng",
	xindenglumimaguize: "Vui lòng nhập kết hợp 6-16 chữ số và chữ cái cho mật khẩu đăng nhập mới",
	xinmima: "mật khẩu mới",
	querenxinmima: "Xác nhận mật khẩu mới",
	jiumima: "Mật khẩu cũ",
	xiugaidenglumima: "Sửa đổi mật khẩu đăng nhập",
	shurujiumima: "Vui lòng nhập mật khẩu cũ",
	xiugaitixianmima: "Thay đổi mật khẩu rút tiền",
	yuanmimabuyongshuru: "Mật khẩu ban đầu, không cần nhập cho lần thiết lập đầu tiên",
	qingshuruxinmima: "Vui lòng nhập mật khẩu mới của bạn",
	queding: "Chắc chắn rồi",
	tianjiayinhangka: "Thêm thẻ ngân hàng",
	profit: {
		biaoti: 'Sự kiện đặc biệt',
		name: '7 ngày Quản lý tài chính',
		jine: 'Số lượng',
		shouru: 'thu nhập',
		cunkuanshijian: 'Thời gian gửi tiền',
		daoqi: 'Ngày hết hạn',
		cunkuanjine: 'Số tiền gửi',
		cunkuan: 'Hãy để nó ngay bây giờ.',
		shurujine: 'Nhập số tiền',
		goumai: 'mua',
		quxiao: 'Hủy bỏ',
	},
	fund: {
		biaoti: 'Chi tiết quỹ',
		tikuan: 'Rút tiền',
		zhuangtai: 'trạng thái',
		shijian: 'thời gian',
		nomore: 'Không có thông tin bổ sung',
	},
	txmoney: {
		Withdrawcash: 'rút',
		AmountofCashWithdrawal: 'Số tiền có thể rút',
		Withdrawcashamount: 'Số tiền rút',
		WithdrawPassword: 'Mật khẩu rút tiền',
		Pleaseenterthewithdrawalamount: 'Vui lòng nhập số tiền rút',
		Pleaseenteryourwithdrawalcode: 'Vui lòng nhập mật khẩu rút tiền'
	},
	touzijilu: {
		biaoti: 'Hồ sơ đầu tư',
		lunshu: 'Số chu kỳ',
		danbijine: 'Số tiền đầu tư một lần',
		huoli: 'Số tiền lãi',
		leixing: 'Loại giao dịch',
		zhuangyai: 'trạng thái',
		shijian: 'thời gian',
		Pendingearnings: 'Đang chờ thu nhập',
		Investmentsuccess: 'đầu tư thành công',
		investmentfailure: 'thất bại đầu tư'
	},
	game: {
		game1: 'Bảo vệ Đại Dương',
		game2: 'Khoảng cách',
		game3: 'Vòng',
		game4: 'Hạn chót',
		game5: 'Nhỏ',
		game6: 'Chẵn',
		game7: 'Tổng',
		game8: 'Hòa:',
		game9: 'Kết quả Xổ số',
		game10: 'Biểu đồ xu hướng',
		game11: 'Xu hướng kích thước',
		game12: 'Xu hướng Chẵn/Lẻ',
		game13: 'Lớn',
		game14: 'Đơn',
		game15: 'Số lượng cược trong vòng này',
		game16: 'Cược',
		game17: 'Xem cược trong vòng này',
		game18: 'Xem Thông tin theo dõi',
		game19: 'Đang cược cho vòng',
		game20: 'Theo dõi',
		game21: 'Đặt để tự động dừng theo dõi sau khi thắng trong số vòng quy định',
		game22: 'Nếu không thắng, dừng theo dõi sau khi chơi số vòng đã đặt',
		game23: 'Chọn Số vòng:',
		game24: 'Mua trước',
		game25: 'Tổng Mua trước:',
		game26: 'Pha trộn',
		game27: 'Số đặc biệt',
		game28: 'Mua trước',
		game29: 'Cách chơi:',
		game30: 'Lớn Đơn:',
		game31: 'Lớn Chẵn:',
		game32: 'Nhỏ Đơn:',
		game33: 'Nhỏ Chẵn:',
		game34: 'Số lớn:',
		game35: 'Số nhỏ:',
		game36: 'Số ba:',
		game37: 'Sóng Đỏ:',
		game38: 'Sóng Xanh:',
		game39: 'Sóng Xanh Dương:',
		game40: 'Chọn cách chơi, nhấp để mua với số tiền:',
		game41: 'Số tiền mua:',
		game42: 'Theo dõi:',
		game43: 'Mua:',
		game44: 'Đặt lại:',
		game45: 'Quay lại:',
		game46: 'Mua vào:',
	}
}