<template>
	<div class="container">
		<div id="Setup">
			<div class="vux-header" style="background: rgb(240, 185, 1); color: rgb(255, 255, 255);">
				<div class="vux-header-left" onclick="window.history.back(-1)">
					<a class="vux-header-back"></a>
					<div id="left-arrow" class="left-arrow"></div>
				</div>
				<h1 class="vux-header-title">{{ $t('touzijilu.biaoti') }}</h1>
				<div class="vux-header-right"></div>
			</div>
			<div class="touzijilu">
				
				<div class="listdata" v-for="item in list">
					<div class="wid100">{{ $t('touzijilu.lunshu') }}：<font style="color:#EDCB7B;">{{item.number}}</font> / ทำรายการเดี่ยว</div>
					<div class="wid100">{{ $t('touzijilu.danbijine') }}：<font style="color:#EDCB7B;">{{item.num}}</font>
					</div>
					<div v-if="item.income < 0" class="wid100">{{ $t('touzijilu.huoli') }}：<font style="color:#DF4D5B;">{{item.income}}</font></div>
					<div v-else-if="item.income > 0" class="wid100">{{ $t('touzijilu.huoli') }}：<font style="color:#EDCB7B;">+ {{item.income}}</font></div>
					<div v-else class="wid100">{{ $t('touzijilu.huoli') }}：</div>
					<div>{{ $t('touzijilu.leixing') }}：{{item.andtype}}</div>
					<div v-if="item.status==1">{{ $t('touzijilu.zhuangyai') }}：{{ $t('touzijilu.Pendingearnings') }}</div>
					<div v-else-if="item.status==2">{{ $t('touzijilu.zhuangyai') }}：{{ $t('touzijilu.Investmentsuccess') }}</div>
					<div v-else-if="item.status==3">{{ $t('touzijilu.zhuangyai') }}：{{ $t('touzijilu.investmentfailure') }}</div>
					<div class="wid100">{{ $t('touzijilu.shijian') }}：{{item.create_time}}</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
// 功能引入
import {
	toRefs,
	reactive,
	onMounted,
	onUnmounted
} from 'vue'

import { showToast } from 'vant';
import { Record,Thborder } from '@/api/api'
	
	
export default {
	setup() {

		// 数据
		const State = reactive({
			list:[]
		});

		// 方法
		const Function = reactive({
			onload(){
				if(sessionStorage.getItem('username')){
					Thborder().then(res => {
						console.log(res.data)
						State.list = res.data.info
					}).catch((error) => {
						//console.log(error)
						showToast(error.message)
					})
				}
				
			},
			gototab4() {
				this.$router.push({
					path: "/tab4"
				});
			},
		});
		// 接口
		const Interface = reactive({});

		onMounted(() => {
			Function.onload()
		});
		onUnmounted(() => {
			//销毁
		});
		return {
			...toRefs(State),
			...toRefs(Function),
			...toRefs(Interface),
		}
	},
}
</script>

<!-- <style type="text/css" scoped> -->
<style type="text/css">
.touzijilu .listdata {
	margin: 10px 15px;
	border-radius: 6px;
	background: url('../assets/images/touzijilu/my-bg1.png');
	background-size: 100% 100%;
	padding: 5px 0;
}

.touzijilu .listdata div {
	color: #fff;
	font-size: 0.25rem;
	padding: 6px 15px;
	width: 50%;
	display: inline-block;
}
.wid100{
	width: 100%!important;
}

.container {
	width: 100%;
	height: 100vh;
	margin: 0;
	padding: 0;
}

#Setup {
	height: 100%;
	background-color: #ffffff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

#Setup .weui-cell:before {
	border-color: transparent !important;
}

#tablebody .mui-table-view-cell {
	padding-bottom: 0px;
}


#Setup a {
	color: #333;
	width: 100%;
	height: 100%;
	display: block;
}

#Setup a:link {
	color: #333;
}

#Setup .wrap2 {
	width: 100%;
	height: 100%;
	background: url('@/assets/images/fund/base-bg2.png');
	background-size: 100% 100%;
}

#Setup .vux-header-left .left-arrow:before {
	border-color: #222222 !important;
}

#Setup .vux-header .vux-header-title {
	color: #222222;
}

#Setup .submit {
	width: 100%;
	margin-top: 0.523rem;
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

#Setup .submit .submit-btn {
	position: absolute;
	bottom: 1.1rem;
	left: 1.74rem;
	right: 1.74rem;
	height: 0.9rem;
	line-height: 0.88rem;
	margin: 0 auto;
	display: block;
	background-image: -webkit-gradient(linear, left bottom, left top, from(#ceab77), to(#eddbc1));
	background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
	border-radius: .45rem;
	color: #373430;
	text-align: center;
	font-size: 0.34rem;
	font-weight: normal;
	font-stretch: normal;
}

#Setup .barrageSwitch {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

#Setup .barrageSwitch .iconfont {
	font-size: 0.3rem;
	width: 0.4rem;
	margin-left: 0.22rem;
	text-align: center;
	margin-right: 0.1rem;
}

#Setup .barrageSwitch .vux-x-switch {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

#Setup .barrageSwitch .weui-cell__bd {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

#Setup .barrageSwitch .weui-label {
	font-size: 0.3rem;
	color: #fff8e9;
	padding-left: .2rem;
}

#Setup .barrageSwitch .weui-cell {
	padding: 10px 15px 10px 0;
}

.vux-header {
	position: relative;
	padding: 3px 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	background-color: #35495e;
}

.vux-header .vux-header-title {
	line-height: 40px;
	text-align: center;
	font-size: 18px;
	font-weight: 400;
	color: #fff;
}

.vux-header-title-area,
.vux-header .vux-header-title {
	margin: 0 88px;
	height: 40px;
	width: auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.vux-header .vux-header-title>span {
	display: inline-block;
}

.vux-header .vux-header-left,
.vux-header .vux-header-right {
	position: absolute;
	top: 14px;
	display: block;
	font-size: 14px;
	line-height: 21px;
	color: #ccc;
}

.vux-header .vux-header-left a,
.vux-header .vux-header-left button,
.vux-header .vux-header-right a,
.vux-header .vux-header-right button {
	float: left;
	margin-right: 8px;
	color: #ccc;
}

.vux-header .vux-header-left a:active,
.vux-header .vux-header-left button:active,
.vux-header .vux-header-right a:active,
.vux-header .vux-header-right button:active {
	opacity: 0.5;
}

.vux-header .vux-header-left {
	left: 18px;
}

.vux-header .vux-header-left .vux-header-back {
	padding-left: 16px;
}

.vux-header .vux-header-left .left-arrow {
	position: absolute;
	width: 30px;
	height: 30px;
	top: -5px;
	left: -5px;
}

.vux-header .vux-header-left .left-arrow:before {
	content: "";
	position: absolute;
	width: 12px;
	height: 12px;
	border: 1px solid #ccc;
	border-width: 1px 0 0 1px;
	-webkit-transform: rotate(315deg);
	transform: rotate(315deg);
	top: 8px;
	left: 7px;
}

.vux-header .vux-header-right {
	right: 15px;
}

.vux-header .vux-header-right a,
.vux-header .vux-header-right button {
	margin-left: 8px;
	margin-right: 0;
}

.vux-header .vux-header-right .vux-header-more:after {
	content: "\2022   \2022   \2022   ";
	font-size: 16px;
}

.vux-header-fade-in-right-enter-active {
	-webkit-animation: fadeinR .5s;
	animation: fadeinR .5s;
}

.vux-header-fade-in-left-enter-active {
	-webkit-animation: fadeinL .5s;
	animation: fadeinL .5s;
}

@-webkit-keyframes fadeinR {
	0% {
		opacity: 0;
		-webkit-transform: translateX(150px);
		transform: translateX(150px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes fadeinR {
	0% {
		opacity: 0;
		-webkit-transform: translateX(150px);
		transform: translateX(150px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@-webkit-keyframes fadeinL {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-150px);
		transform: translateX(-150px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes fadeinL {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-150px);
		transform: translateX(-150px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@import '@/assets/css/app.css';</style>
