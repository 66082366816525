import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/pages/LayoutView.vue'
import HomeTab from '@/pages/HomeTab.vue'
import TabBar1 from '@/pages/TabBar1.vue'
import TabBar2 from '@/pages/TabBar2.vue'
import TabBar4 from '@/pages/TabBar4.vue'
import walletPage from '@/pages/WalletPage.vue'
import singing from "@/pages/singing.vue";
import boosting from "@/pages/boosting.vue"

const routes = [{
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: '/homeTab',
        meta: {
            needLogin: true
        },
        children: [{
                path: '/homeTab',
                name: 'HomeTab',
                component: HomeTab,
                meta: {
                    index: 0
                }
            },
            {
                path: '/tab1',
                name: 'tab1',
                component: TabBar1,
                meta: {
                    index: 1
                }
            },
            {
                path: '/tab2',
                name: 'tab2',
                component: TabBar2,
                meta: {
                    index: 2
                }
            },
            {
                path: '/tab4',
                name: 'tab4',
                component: TabBar4,
                meta: {
                    index: 3
                }
            },
            {
                path: '/wallet',
                name: 'wallet',
                component: walletPage,
                meta: {
                    index: 3
                }
            },
            {
                path: '/singing',
                name: 'singing',
                component: singing,
                meta: {
                    index: 4
                }
            },
            {
                path: '/boosting',
                name: 'boosting',
                component: boosting,
                meta: {
                    index: 5
                }
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('@/pages/login'),
        meta: {
            needLogin: false
        }
    },
    {
        path: '/registe',
        name: 'registe',
        component: () =>
            import ('@/pages/registe'),
        meta: {
            needLogin: false
        }
    },
    {
        path: '/shezhi',
        name: 'shezhi',
        component: () =>
            import ('@/pages/shezhi'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/yuyanshezhi',
        name: 'yuyanshezhi',
        component: () =>
            import ('@/pages/yuyanshezhi'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/recharge',
        name: 'recharge',
        component: () =>
            import ('@/pages/recharge'),//充值
        meta: {
            needLogin: true
        }
    },
    {
        path: '/card',
        name: 'card',
        component: () =>
            import ('@/pages/cardInfo'),//银行卡
        meta: {
            needLogin: true
        }
    },
    {
        path: '/cash',
        name: 'cash',
        component: () =>
            import ('@/pages/cashBank'),//银行卡提现
        meta: {
            needLogin: true
        }
    },
    {
        path: '/editbank',
        name: 'editbank',
        component: () =>
            import ('@/pages/editbank/editbank'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/remen',
        name: 'remen',
        component: () =>
            import ('@/pages/remen'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/jiaoyizhongxin',
        name: 'jiaoyizhongxin',
        component: () =>
            import ('@/pages/jiaoyizhongxin'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/securityCenter',
        name: 'securityCenter',
        component: () =>
            import ('@/pages/securityCenter'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/blackRock',
        name: 'blackRock',
        component: () =>
            import ('@/pages/blackRock'),
        meta: {
            needLogin: true
        }

    },
    {
        path: '/chanpingjieshao',
        name: 'chanpingjieshao',
        component: () =>
            import ('@/pages/chanpingjieshao'),
        meta: {
            needLogin: true

        }

    },
	{
	    path: '/txmoney',
	    name: 'txmoney',
	    component: () =>
	        import ('@/pages/txmoney'),
	    meta: {
	        needLogin: true

	    }

	},
    {
        path: '/tixian',
        name: 'tixian',
        component: () =>
            import ('@/pages/tixian'),
        meta: {
            needLogin: true

        }

    },
    {
        path: '/tixianbank',
        name: 'tixianbank',
        component: () =>
            import ('@/pages/tixianbank'),
        meta: {
            needLogin: true

        }

    },
    {
        path: '/loginPwd',
        name: 'loginPwd',
        component: () =>
            import ('@/pages/loginPwd/loginPwd'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/bankPwd',
        name: 'bankPwd',
        component: () =>
            import ('@/pages/loginPwd/bankPwd'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/fund',
        name: 'fund',
        component: () =>
            import ('@/pages/fund'),
        meta: {
            needLogin: true
        }
    },
	{
	    path: '/funds',
	    name: 'funds',
	    component: () =>
	        import ('@/pages/funds'),
	    meta: {
	        needLogin: true
	    }
	},
    {
        path: '/touzijilu',
        name: 'touzijilu',
        component: () =>
            import ('@/pages/touzijilu'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/profit',
        name: 'profit',
        component: () =>
            import ('@/pages/profit'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/game',
        name: 'game',
        component: () =>
            import ('@/pages/game'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/gameinfo',
        name: 'gameinfo',
        component: () =>
            import ('@/pages/gameinfo'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/account',
        name: 'account',
        component: () =>
            import ('@/pages/account'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/user_money_pwd',
        name: 'user_money_pwd',
        component: () =>
            import ('@/pages/loginPwd_new'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/user_edit',
        name: 'user_edit',
        component: () =>
            import ('@/pages/UserEdit'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/haoma',
        name: 'haoma',
        component: () =>
            import ('@/pages/HaoMa'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/hongbao',
        name: 'hongbao',
        component: () =>
            import ('@/pages/HongBao'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/resetPassword',
        name: 'resetPassword',
        component: () =>
            import ('@/pages/resetPassword'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/myOrderInfo',
        name: 'myOrderInfo',
        component: () =>
            import ('@/pages/myOrderInfo'),
        meta: {
            needLogin: true
        }
    },
    {
        path: '/moreProject',
        name: 'moreProject',
        component: () =>
            import ('@/pages/moreProject'),
        meta: {
            needLogin: true
        }
    },
	{
	    path: '/invitation',
	    name: 'invitation',
	    component: () =>
	        import ('@/pages/invitation'),
	    meta: {
	        needLogin: true
	    }
	},
	{
	    path: '/rule',
	    name: 'rule',
	    component: () =>
	        import ('@/pages/rule'),
	    meta: {
	        needLogin: true
	    }
	},
    {
        path:'/deposit',
        name:'deposit',
        component: () => import('@/pages/deposit/deposit.vue'),
        meta: {
            needLogin: true
        }
    },
    {
        path:'/depositSuccess',
        name:'depositSuccess',
        component: () => import('@/pages/deposit/depositSuccess.vue'),
        meta: {
            needLogin: true
        }
    },
    {
        path:'/withdraw',
        name:'withdraw',
        component: () => import('@/pages/withdraw/withdraw.vue'),
        meta: {
            needLogin: true
        }
    },
    {
        path:'/withdrawSuccess',
        name:'withdrawSuccess',
        component: () => import('@/pages/withdraw/withdrawSuccess.vue'),
        meta: {
            needLogin: true
        }
    },
    {
        path:'/orders',
        name:'orders',
        component: () => import('@/pages/orders/orders.vue'),
        meta: {
            needLogin: true
        }
    },
    {
        path:'/ordersDetail',
        name:'ordersDetail',
        component: () => import('@/pages/orders/ordersDetail.vue'),
        meta: {
            needLogin: true
        }
    },
    {
        path:'/billRecord',
        name:'billRecord',
        component: () => import('@/pages/billRecord/billRecord.vue'),
        meta: {
            needLogin: true
        }
    },
    {
        path:'/Invite',
        name:'Invite',
        component: () => import('@/pages/Notifications/Invite.vue'),
        meta: {
            needLogin: false
        }
    },
    {
        path:'/auth',
        name:'auth',
        component: () => import('@/pages/Notifications/auth.vue'),
        meta: {
            needLogin: false
        }
    },
    {
        path:'/faq',
        name:'faq',
        component: () => import('@/pages/Notifications/faq.vue'),
        meta: {
            needLogin: false
        }
    },
    {
        path:'/Notifications',
        name:'Notifications',
        component: () => import('@/pages/Notifications/Notifications.vue'),
        meta: {
            needLogin: false
        }
    },
    {
        path:'/Tips',
        name:'Tips',
        component: () => import('@/pages/Notifications/Tips.vue'),
        meta: {
            needLogin: false
        }
    },
	{
	    path:'/service',
	    name:'service',
	    component: () => import('@/pages/service.vue'),
	    meta: {
	        needLogin: false
	    }
	},
	{
        path:'/financial',
        name:'financial',
        component: () => import('@/pages/financial.vue'),
	    meta: {
	        needLogin: false
	    }
	},
	{
        path:'/deposits',
        name:'deposits',
        component: () => import('@/pages/deposits.vue'),
	    meta: {
	        needLogin: false
	    }
	},
	{
        path:'/withdraw',
        name:'withdraw',
        component: () => import('@/pages/withdraw.vue'),
	    meta: {
	        needLogin: false
	    }
	},
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes
})
