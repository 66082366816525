export default {
    home:{
        "768194-0": "エクスペリエンスボーナス",
"768194-1": "簡体字",
"768194-2": "繁体字",
"768194-3": "慈善の伝統的美徳を広め、社会の進歩を促進し、多多助力購とともにオンラインデジタルエンターテイメントプラットフォームを作り、毎日のタスクで最大257,760ボーナスを受け取ることができ、幸せな人生をお祈りします〜",
"768194-4": "シンガポール大彩",
"768194-5": "4Dロト、ビッグプライズで遊ぶ",
"768194-6": "入場",
"768194-7": "万字券",
"768194-8": "宝探し大作戦",
"768194-9": "多多ロト",
"768194-10": "ラッキージャックポット、あなたが勝つのを待っています",
"768194-11": "18か月以上寄付してくれたすべての益童支援者に感謝します！",
"768194-12": "寄付する",
"768194-13": "ウォレット",
"768194-14": "愛の貢献",
"985138-0": "ログイン",
"985138-1": "簡体字",
"985138-2": "繁体字",
"985138-3": "愛のリレー、心が希望でいっぱいになるように",
"985138-4": "ようこそ",
"985138-5": "サインアップ",
"985138-6": "アカウントを入力",
"985138-7": "パスワードを入力",
"985138-8": "今すぐログイン",
"063348-0": "サインアップ",
"063348-1": "簡体字",
"063348-2": "繁体字",
"063348-3": "愛のリレー、心が希望でいっぱいになるように",
"063348-4": "ようこそ",
"063348-5": "ログイン",
"063348-6": "サインアップ",
"063348-7": "6〜20文字の英数字または組み合わせを入力してください",
"063348-8": "6〜16文字の英数字の組み合わせを入力してください",
"063348-9": "招待コードを入力してください",
"063348-10": "代理人の招待コードを入力してください",
"063348-11": "私は18歳以上であり、",
"063348-12": "登録規約",
"063348-13": "今すぐサインアップ",
"063348-80":"请输入确认密码",
        ckgd:'続きを見る',
        jcyx:'素晴らしいゲーム',
        ax_tips:'皆様の長期的なご支援は、宜通プロジェクトの発展を成功させ影響力を高める上で重要な役割を果たします。 この継続的な支援は社会福祉と子どもたちの福祉にプラスの影響を与え、子どもたちにより良い生活と教育の機会を提供します。',
        jzlb:'寄付リスト',
        czcj:'ストアバリュー宝くじ',
        ljcj:'今すぐ描く',
    },
    tabbar: {
        Home: 'トップページ',
        Recharge: 'Recharge',
        Trend: 'Trend',
        My: 'マイ',
		kefu:'カスタマーサービス'
    },
    kefu: {
        title: 'オンラインカスタマーサービス  ',
        t1: 'お問い合わせ',
        q1:'1.チャージはどうすればいいのか？',
        q1a:'銀行カード振込のため、カスタマーサービスへのご連絡ください。',
        q2:'2.パスワードを忘れたらどうしよう？',
        q2a:'時間内にカスタマーサービスに適切な情報を提供してください、システムは新しいパスワードをリセットします、アカウントのセキュリティを保護するために、ユーザーがログインした後、すぐにパスワードを変更することをお勧めします。',
        q3:'3.オンライン決済を開くにはどうしたらいいですか？',
        q3a:'銀行カードのオンライン決済機能を開設するには、オンライン開設と現場開設の2つの方法があります：',
        q3b:'(1)オンライン開設：銀行の公式サイトにログインし、オンラインで開設します。',
        q3c:'(2)現場開設：銀行の営業店へ行って、現場で開設します。',
        q4:'4.出金回数に制限はあるのか？',
        q4a:'当社は、出金回数を制限していません。',
        q5:'5.出金に手数料はかかりますか？',
        q5a:'出金要件を満たした場合のみ出金可能、出金回数に制限なし、手数料は本サイトにより定めます。出金時に発生する銀行手数料は、すべて本サイトがお客様に代わって負担します。',
        q6:'6.会社の情報はどこで知ることができますか？',
        q6a:'会社公式サイトアドレスです：',
        kfxt:'カスタマーサービスのリトルT',
        t001:'こんにちは！ インテリジェントな顧客サービスをご提供します~',
    },
    my:{
        t1:'残高',
        t2:'財布',
        t3:'流水',
        t4:'アカウント情報',
        t5:'パスワードの変更',
        t6:'ファンドのパスワードを設定',
        t7:'ログアウト',
    },
    wallet: {
        t1: '口座残高',
        t2: '利用可能残高',
        t3: '金額凍結',
        t4: 'チャージ',
        t5: '現金の引き出し',
        t6: '銀行カード',
        t7: '取引履歴',
    },
    cash:{
        t1:'出金申請',
        t2:'最大引出し金額：',
        t3:'資金のパスワード',
        t4:'シングルストローク',
        t5:'手数料',
        t6:'実際の入金',
        t7:'名前',
        t8:'銀行名',
        t9:'銀行口座番号',
        t10:'管理人へのコメントを残すことができます',
    },
    cardInfo:{
        t1:'銀行カード管理',
        t2:'マイバンクカード',
        t3:'銀行カードが制限されている場合、カスタマーサービスにご連絡ください',
        t4:'ヒント：',
        t5:'1. 銀行カードは1枚のみ紐付け可能',
        t6:'2. 紐付けた後、カードで現金を引き出すことができる',
        t7:'お金を引き出したい',
    },
    recharge: {
        t1: 'アカウントチャージ',
        t2: 'チャージ金額',
        t3: '最低チャージ金額$100',
        t4: '受取りアカウント番号',
        t5: '支払方法',
        t6: 'チャージ為替レート',
        t7: 'ヒント',
        t8: '1、口座番号をご確認ください。最低チャージ額は：$100',
        t9: '2、上記に記載されているお支払い方法に従って、お支払いください。',
        t10: '3、お支払いの際、お支払いの対象となる注文番号を控えていただき、審査のために送信してください。送信の後、入金の確認をお待ちください。',
    },
    common_text: {
        tj: '提出',
        RM: '英ポンド',
        EUR: 'ユーロ',
        USD: '米ドル',
        JPY: '円',
    },
    singing: {
        logo_title: "愛の奉納",
        title1: "18ヶ月以上の継続的な寄付者の皆様に感謝します！",
        title2: "寄付",
        title3: "支援金",
        title4: "私の累計慈善寄付",
        title5: "寄付可能額",
        title6: "愛の奉納",
        title7: "持続可能で成功した事業を運営する一方で、慈善助成金は、ビジネスが展開されている国やコミュニティに肯定的な影響をもたらし、国やコミュニティの発展と成長を積極的にサポートし、慈善活動に参加することで企業の社会的責任を果たし、次世代のためにより良い未来を築くために活動しています。この約束を守り、慈善助成金は、若者の積極的な人生観、教育、雇用を育むことに焦点を当てた慈善活動を実施するために設立されました。次世代が生活水準を向上させ、より包括的で調和のとれた環境を築くのを助けるために、若者が責任ある社会の一員となることを奨励します。将来を展望し、慈善助成金は引き続き、より包括的で調和のとれた社会の構築をサポートし、人間性と博愛の精神を発揮するプロジェクトに取り組み、貧困や助けが必要な人々に奉仕し、彼らと一緒に貧困や災害に立ち向かい、困難を乗り越えるために力を合わせています。現在、このグループは主に以下の慈善活動をサポートしています。",
        title8: "ホープ小学校プロジェクト",
        title9: "2. アフリカの子供たちに最低限の生活必需品、食品、薬品、抗マラリアワクチン、蚊帳などを寄付し、2019年の新型コロナウイルス感染症の大流行は、世界的な危機を引き起こし、アフリカの子供たちの生活を覆し、長らく存在していた不平等な状況が加速し、最も貧しい国やコミュニティに住む子供たちが最も厳しい打撃を受けました。当グループのアフリカの子供たちへの支援金は、着実に積み上げられています。",
        title10: "聴覚障害の子供たち",
        title11: "3. 聴覚障害の子供たちを支援し、毎年プログラムに参加する聴覚障害の子供たちは、このプログラムを通じてセント・ジョセフ教区の第5学校に入学し、他の幼児たちと一緒に「統合クラス」に入学します。初めて5人の子供たちは、聴覚のある教師と聴覚のない教育助手が共同で授業を行う統合クラスで3年間学習した後、2021年7月に幼稚園を卒業しました。このプログラムが過去3年間成功裡に実施されたことを鑑み、マカオ教区は学校の実施主体としてプログラムを持続的に実践プロジェクトと位置付け、プログラムに参加する聴覚障害の子供たちが小学校の教育を受け、中学校の教育も受けることができるようにすることを期待しています。聴覚障害の子供たちの成長段階や成長過程についての知識を深め、彼らが成長と発達を遂げるのをどのように助けるかを理解し、このプログラムは着実に積み上げられています。",
        title12: "毎日のサポート説明",
        title13: "1. メンバーユーザーは毎日1回、サポート金を受け取ることができます。",
        title14: "2. 毎日のサポートは社会に還元する目的であり、非営利活動です。",
        title15: "3. 毎日のサポートで得たサポート金は引き出すことはできず、取引することもできません。",
        title16: "4. 毎日のサポートで得たサポート金の使用権は、アカウント所有者に帰属し、慈善寄付プロジェクトにのみ使用できます。当グループを通じて関連する慈善プロジェクトに寄付できます。",
        title17: "慈善助成金の説明",
        title18: "1. ユーザーがゲームで利益を得るたびに、プラットフォームは中奨金額の0.01%を追加で支払い、ユーザーの慈善助成金に累積されます。",
        title19: "2. 慈善助成金は社会に還元する目的であり、非営利活動であり、プラットフォームの収益には使用できません。",
        title20: "3. 慈善助成金の得られた寄付金は引き出すことができず、取引することもできません。",
        title21: "4. 慈善助成金の得られた寄付金の使用権は、アカウント所有者に帰属し、慈善寄付プロジェクトにのみ使用できます。当グループを通じて関連する慈善プロジェクトに寄付できます。",
        title22: "〈私たちの計画に参加〉",
        title23: "〈明日の夢を描き直す〉",
        title24: "〈子供たちのために手を携える〉",
        title25: "ホープ小学校",
        title26: "アフリカの子供たちへの支援",
        title27: "聴覚障害の子供たち",
        title28: "寄付"
    },
    loginpwd_new:{
        title1: "セキュリティパスワードの設定",
        title2: "古いパスワード",
        title3: "古いパスワード（初回の設定では入力不要）",
        title4: "新しいセキュリティパスワード",
        title5: "新しいパスワード",
        title6: "セキュリティパスワードを確認",
        title7: "パスワードを確認",
        title8: "確認コード",
        title9: "保存",
        title10: "ヘルプが必要な場合は、",
        title11: "カスタマーサービスにお問い合わせください",
        title12: "パスワードと確認コードを入力してください"
    },
    account: {
        title1: "取引履歴",
        title2: "過去1か月の取引履歴のみ表示され、詳細情報が必要な場合はカスタマーサービスにお問い合わせください",
        title3: "前へ",
        title4: "次へ",
        title5: "引き出し",
        title6: "ベット",
        title7: "利益",
        title8: "入金",
        title9: "今日",
        title10: "昨日",
        title11: "過去7日間",
        title12: "今月"
      },
    Lottery:{
        txt1: "口座情報",
        txt2: "名前",
        txt3: "パーソナライズされたニックネームを設定する",
        txt4: "保存",
        txt5: "ヒント",
        txt6: "名前と受取銀行カードは一致している必要があり、一致していないと引き出しは失敗します。",
        txt7: "アカウントのパスワードは安全に保管し、他人に貸与して使用しないでください。",
        txt8: "抽選結果",
        txt9: "リフレッシュする",
        txt10: "番号",
        txt11: "サイズ",
        txt12: "奇数と偶数",
        txt13: "和",
        txt14: "小さい",
        txt15: "ペア",
        txt16: "データなし",
        txt17: "チャージ抽選",
        txt18: "現在、宝くじのエントリーはありません",
        txt19: "今日の残り時間",
        txt20: "その他の受賞者リスト",
        txt21: "賞品一覧",
        txt22: "ルールの説明",
        txt23: "赤い封筒の抽選数は毎日更新され、累積されません。",
        txt24: "抽選で有効な赤い封筒の金額を集めることができます。",
        txt25: "紅包くじの最終解釈権は当社に帰属します。",
        txt26: "宝くじの赤い封筒の額が 1,000 個の無料アクティベーション制限を超えた場合、規定に従ってアクティベートした後でのみ引き出すことができます。",
        txt27: "ボーナス獲得おめでとうございます",
    },

	jzcg:'寄付が成功した',
	jztop:'寄付できる金額はありません',
	qdcg:'署名に成功しました',
	yjqd:'チェックイン済み',

	mqmyjh:'現在、宝くじのエントリーはありません',
	gdzlxm:'その他の支援プロジェクト',
	gdtop:'さらなる支援プロジェクトにご期待ください',
	xianzhi:'警告: 数量制限',

	yaoqingma:'招待コード',
	yiji:'レベル1',
	erji:'レベル2',
	sanji:'レベル 3',
	fzcg:'正常にコピーされました',
	fzsb:'コピーに失敗しました',

	xgdlmm:'ログインパスワードを変更する',
	gxzj:'優勝おめでとうございます',
	zjjj:'ボーナス',

	zcmmts:'2回入力したパスワードは一致しません',

	newtext1:'毎日のチェックイン',
	newtext2:'月曜日',
	newtext3:'火曜日',
	newtext4:'水曜日',
	newtext5:'木曜日',
	newtext6:'金曜日',
	newtext7:'土曜日',
	newtext8:'日曜日',
	newtext9:'サインイン',

	newtext10:'情報',
	newtext11:'購入を確認する',
	newtext12:'キャンセル',
	newtext13:'選ばれた',
	newtext14:'ゲームプレイと合計金額に注意してください:',

	newtext15:'周大福ネックレス',
	newtext16:'炊飯器',
	newtext17:'幸運の赤い封筒',
	newtext18:'日本への10日間の旅行',
	newtext19:'ロレックス時計',
	newtext20:'幸運の赤い封筒',

	newtext21:'確認コードを入力してください',

	newtext22:'パーソナルセンター',

	newtext23:'ユーザー センター',
	newtext24:'私の財布',
	newtext25:'アカウント管理',
	newtext26:'セキュリティ下のセキュリティ',

	newtext27:'タイプ',
	newtext28:'時間',

	newtext29:'メッセージを残す',
	newtext30:'出金金額',
	
	newtext31:'アカウント残高',
    newtext32:'レコード破り'
}
