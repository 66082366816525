<template>
  <van-tabbar  class="tabbar" v-model="active" route>
    <van-tabbar-item replace to="/homeTab">
      <template #icon="props">
		<div v-if="props.active" class="iconfont icon-a-shouyexuanzhong" style="text-align: center;font-size: 21px;background-image: linear-gradient(to right, rgb(0, 117, 253), rgb(0, 117, 253));-webkit-background-clip: text;color: transparent;font-weight: bold;"></div>
		<div v-else class="iconfont icon-shouye" style="color: rgb(153, 153, 153);text-align: center;font-size: 21px;font-weight: bold;"></div>
		<p v-if="props.active" class="atitlesx">{{$t('newtabbar.Home')}}</p>
		<p v-else class="titlesx">{{$t('newtabbar.Home')}}</p>
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/tab1">
      <template #icon="props">
		<div v-if="props.active" class="iconfont icon-jiaoyisuo1" style="text-align: center;font-size: 21px;background-image: linear-gradient(to right, rgb(0, 117, 253), rgb(0, 117, 253));-webkit-background-clip: text;color: transparent;font-weight: bold;"></div>
		<div v-else class="iconfont icon-jiaoyisuo1" style="color: rgb(153, 153, 153);text-align: center;font-size: 21px;font-weight: bold;"></div>
		<p v-if="props.active" class="atitlesx">{{$t('newtabbar.Quantization')}}</p>
		<p v-else class="titlesx">{{$t('newtabbar.Quantization')}}</p>
      </template>
    </van-tabbar-item>
	<van-tabbar-item to="/tab4">
	  <template #icon="props">
		<div v-if="props.active" class="iconfont icon-a-wodexuanzhong" style="text-align: center;font-size: 21px;background-image: linear-gradient(to right, rgb(0, 117, 253), rgb(0, 117, 253));-webkit-background-clip: text;color: transparent;font-weight: bold;"></div>
		<div v-else class="iconfont icon-wode" style="color: rgb(153, 153, 153);text-align: center;font-size: 21px;font-weight: bold;"></div>
		<p v-if="props.active" class="atitlesx">{{$t('newtabbar.Assets')}}</p>
		<p v-else class="titlesx">{{$t('newtabbar.Assets')}}</p>
	  </template>
	</van-tabbar-item>
  </van-tabbar>
</template>

<script setup>
import {ref} from 'vue'

const active = ref(0);
</script>

<style scoped lang="scss">
.tabbar{
  background: #ffffff;
  height: 50px;
	
	.atitlesx{
		font-size: 11px;
				margin-top: 5px;
				text-align: center;
		background-image: linear-gradient(to right, rgb(0, 117, 253), rgb(0, 117, 253));
		-webkit-background-clip: text;
		color: transparent;
		font-weight: bold;
	}
  .titlesx{
	  color: rgb(153, 153, 153);
	  font-size: 11px;
		margin-top: 5px;
		text-align: center;
		font-weight: bold;
  }
  .image{
    width: 18px;
    height: 18px;
	margin: 0 auto;
	margin-top: 10px;
    margin-bottom: 3px;
  }
  .van-tabbar-item{
	  color: #ffffff;
  }
  .van-tabbar-item--active{
    background: #ffffff;
  }
  
  /deep/ .van-badge__wrapper{
	flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
}

</style>
